import React, { Component } from 'react';
import { inject } from "mobx-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(class FormTextAreaAutoSaved extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false,
            startSaving: false,
            value: this.props.value == null ? "" : this.props.value,
            invalidValue: false,
            wasModified: false,
            typedValue: "",
        }
    
    }

    getValue() {
        return this.state.value;
    }

    close() {
        this.setState({
            value: this.props.value == null ? "" : this.props.value,
            selected: false,
            wasModified: false
        })
    }

    valueChanged(value) {
        this.setState({
            value: value,
            wasModified: true,
        });
    }

    save() {
            this.setState({
                startSaving: true
            }, () => {
                    this.props.validateChange(this.state.value)
                }
            )
        }

    render() {
        return <div className="form-field d-flex">
                <textarea disabled={this.props.locked} onBlur={() => this.save()} onFocus={() => this.setState({ selected : true})} onChange={e => this.valueChanged(e.target.value)} className={"form-control form-control-sm " + (this.state.selected ? "field-selected" : "field-unselected") + " " + (this.state.invalidValue ? "text-danger" : "")} style={{ maxWidth: "100%" }} value={this.getValue()} />
                {this.state.startSaving && this.props.locked &&
                    <div className="action-buttons action-buttons-sm">
                        <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                    </div>
                }
               </div>
               
    }
});