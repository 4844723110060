import React, { Component } from 'react';
import { inject } from "mobx-react";
import  SearchableDropdown  from "./searchable-dropdown";

export default inject("data")(class FormSelectUser extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            isOpen : false,
            chosenOption : this.props.chosenOption === "" ? null : this.props.chosenOption,
        }
    }

    updateOption(option) {
        this.setState({
            isOpen : false,
            chosenOption : option
        });
        this.props.optionChanged(option);
    }

    render() {
                return <SearchableDropdown 
                options={this.props.options}
                label={["first_name", "last_name"]}
                id="username"
                selectedVal={this.props.chosenOption}
                handleChange={(val) => this.updateOption(val)}
              />
    }
});