import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";


export default inject("data")(observer(class DragAndDrop extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dragging: false
        }

        this.dropRef = React.createRef();
    }

    componentDidMount() {
        let div = this.dropRef.current;
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);

        this.dragCounter = 0
    }

    componentWillUnmount() {
        let div = this.dropRef.current;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
    }

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }

    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter--
        if (this.dragCounter > 0) return

        this.setState({dragging: false})
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            //e.dataTransfer.clearData()
            this.dragCounter = 0
            this.setState({dragging: false})
        }
    }

    render() {
        return (
            <div ref={this.dropRef} style={this.state.dragging ? {backgroundColor:"hsl(38, 100%, 48%)"} : {}} className="upload-container p-3">
                {this.props.children}
            </div>
        )
    }
}))