import React from 'react';

const FilterInputField = ({ filterValue, setFilterValue, placeholder, search }) => {
    return (
        <input
            type='text'
            className='form-control search-field'
            value={filterValue}
            placeholder={placeholder}
            onChange={(e) => setFilterValue(e.target.value)}
            onKeyUp={() => search && search()}
        />
    );
};

export default FilterInputField;