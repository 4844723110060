import React, {useEffect, useState} from "react";
import {inject, observer} from 'mobx-react';
import AdminNavigationBar from "../../components/admin-navigation-bar";
import FilterInputField from "../../components/filter-input-field";
import PaginationControls from "../../components/pagination-controls";
import Modal from '../../components/modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faPencilAlt,
    faPlus,
    faSpinner,
    faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import Checkbox from "../../components/checkbox";
import { fetch } from '../../utils/api-wrapper';


const AdminOffices = ({data}) => {
    
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterValue, setFilterValue] = useState("");
    const [sorting, setSorting] = useState([{"id": "name", "desc": false}]);
    const [offices, setOffices] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [client, setClient] = useState(null);
    const [clients, setClients] = useState(null);
    const [showNewOfficeModal, setShowNewOfficeModal] = useState(false);
    const [officesDeleteSelection, setOfficesDeleteSelection] = useState([]);
    const [newOffice, setNewOffice] = useState({
        id_client: "",
        name: "",
        infoBurauId: "",
        address: "",
        zipcode: "",
        alias: []
    });
    const [updatedOfficeData, setUpdatedOfficeData] = useState({
        _id: null,
        name: "",
        address: "",
        zipcode: "",
        alias: []
    });
    const [originalOfficeData, setOriginalOfficeData] = useState({
        _id: null,
        name: "",
        address: "",
        zipcode: "",
        alias: []
    })
    const [showUpdateOfficeModal, setShowUpdateOfficeModal] = useState(false);
    const [showDeleteOfficeModal, setShowDeleteOfficeModal] = useState(false);

    useEffect(() => {
        setLoading(true);
        refreshClients()
    }, []);

    useEffect(() => {
        if (client) {
            setPage(1);
            refreshOffices(client);
        }

    }, [client, pageSize, sorting, filterValue]);

    useEffect(() => {
        if (client) {
            refreshOffices(client)
        }
    }, [page]);

    const refreshClients = () => {
        fetch("/clients/", "GET", {}, response => {
                const clients =  response.data.clients;
                setClients(clients);

                setClient(clients.find(c => c._id === data.id_client)); //Set Client to the user's corresponding client
                setLoading(false);
            },
            error => {
                // Error handling
                setErrorMessage(data.t.read("failed_to_fetch_clients"));
                setLoading(false);
            }
        );
    }

    const selectClient = (client) => {
        setClient({...client})
        setPage(1)
        setFilterValue("");
        refreshOffices(client);
    }

    const refreshOffices = (selectClient = client) => {
        let start = (page - 1) * pageSize;
        const filters = [{id: "id_client", value: selectClient._id}];
        if (filterValue && filterValue.trim() !== "") {
            filters.push({id: "search", value: filterValue.trim()});
        }
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(start)}&page_size=${pageSize}`;
        fetch(  `/offices/${searchData}`, "GET", {},
            response => {
                let fetchedOffices = response.data.offices;
                setOffices([...fetchedOffices]);
                setTotalRowCount(response.data.meta.totalRowCount)
                setLoading(false);
            });
    }

    // Form submission handler
    const updateOfficeSubmit = (e) => {
        e.preventDefault();
        setShowUpdateOfficeModal(false)
        updateOffice()
    };

    const updateOffice = (officeId = updatedOfficeData._id?.$oid, updatedOffice = updatedOfficeData) => {
        const updatedOfficeWithoutId = {...updatedOffice}; // Shallow copy of updatedOffice
        delete updatedOfficeWithoutId._id; // Remove the _id field
        const formData = new FormData();
        formData.append('office', JSON.stringify(updatedOfficeWithoutId));
        fetch(`/offices/${officeId}`, "PUT", formData,
            () => {
                refreshOffices(client);
                setSuccessMessage(data.t.read("office_updated_successfully"))
            },
            () => {
                setErrorMessage(data.t.read("failed_to_update_office"))
                setLoading(false);
            }
        );
    };

    //function to check if an office has been updated
    const isOfficeUpdated = (updatedOfficeData, originalOffice) => {
        return (
            updatedOfficeData.name !== originalOffice.name ||
            updatedOfficeData.address !== originalOffice.address ||
            updatedOfficeData.zipcode !== originalOffice.zipcode ||
            updatedOfficeData.alias?.length !== originalOffice.alias.length ||
            updatedOfficeData.alias.some((alias, index) => alias !== originalOffice.alias[index])
        );
    };

    const openUpdateOfficeModal = (selectedOffice) => {
        setUpdatedOfficeData({
            _id: selectedOffice._id || null,
            name: selectedOffice.name || "",
            address: selectedOffice.address || "",
            zipcode: selectedOffice.zipcode || "",
            alias: selectedOffice.alias || []
        });
        setOriginalOfficeData({
            _id: selectedOffice._id || null,
            name: selectedOffice.name || "",
            address: selectedOffice.address || "",
            zipcode: selectedOffice.zipcode || "",
            alias: selectedOffice.alias || []
        })
        setShowUpdateOfficeModal(true)
    }

    const cancelUpdateOfficeModal = () => {
        setShowUpdateOfficeModal(false);
    }

    const createOffice = (office = newOffice) => {
        // Create form data to send the office data in 'request.form'
        const formData = new FormData();
        formData.append('office', JSON.stringify(office)); // The API expects 'office' in form data

        fetch(  "/offices/", "POST", formData,
            () => {
                setSuccessMessage(data.t.read("office_created_successfully"));
                refreshOffices(client);
            },
            () => {
                alert("Error");
                setLoading(false);
            }
        );
    };

    const newOfficeSubmit = (e) => {
        e.preventDefault();
        setShowNewOfficeModal(false)
        createOffice();
    };

    const openNewOfficeModal = () => {
        setNewOffice({id_client: client._id, infoBurauId: "", address: "", zipcode: "", alias: []})
        setShowNewOfficeModal(true);
    }

    const cancelNewOfficeModal = () => {
        setShowNewOfficeModal(false);
    }

    const deleteOffices = () => {
        fetch("/offices/", "DELETE", {"office_ids": officesDeleteSelection},
            () => {
                refreshOffices();
                setOfficesDeleteSelection([])
            },
            (error) => {
                setErrorMessage(data.t.read("failed_to_delete"))
                setLoading(false);
            }
        );
    }

    const confirmDeleteOffice = () => {
        deleteOffices()
        setShowDeleteOfficeModal(false);
    }

    const openDeleteOfficeModal = () => {
        setShowDeleteOfficeModal(true);
    }

    const cancelDeleteOfficeModal = () => {
        setShowDeleteOfficeModal(false);
        setOfficesDeleteSelection([])
    }

    const isAllOfficesSelected = () => {
        if (!offices || !offices.length) {
            return false;
        }
        // Check if every office in the offices array is included in officesDeleteSelection
        return offices.every(office => officesDeleteSelection.includes(office._id?.$oid));
    }

    const isOfficeSelected = (office) => {
        return officesDeleteSelection.includes(office._id?.$oid);
    }

    const toggleAllOfficesSelected = () => {
        setOfficesDeleteSelection(isAllOfficesSelected() ?
            // If all offices are currently selected, unselect all offices
            officesDeleteSelection.filter(id => !offices.some(office => office._id?.$oid === id))
            :
            // Else, select all offices without duplicating existing selections
            [...officesDeleteSelection, ...offices.map((office) => office._id?.$oid)]
        );
    }

    const sortTable = (name) => {
        const sorter = sorting.find(s => s.id === name)
        if (sorter === undefined)
            return setSorting([{"id": name, "desc": true}])

        if (sorter.desc)
            return setSorting([{"id": name, "desc": false}])
        else
            return setSorting([])
    }

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (rows) => {
        setPage(1)
        setPageSize(rows)
    }

    //pagination logic
    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + offices.length - 1;
    const totalPages = Math.ceil(totalRowCount / pageSize);
    const emptyRowsCount = Math.max(0, pageSize - offices.length);
    const emptyRows = Array(emptyRowsCount).fill({});

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };

    return (
        <div className="card pl-4 pr-4 pb-3 rounded-lg ">
            <AdminNavigationBar data={data} adminPage={"offices"}></AdminNavigationBar>
            <div>
                {!loading ?
                    <div>
                        <div>
                            <div className='d-flex justify-content-between p-2 align-content-center'>
                                <FilterInputField
                                    filterValue={filterValue} setFilterValue={setFilterValue} placeholder={""}>
                                </FilterInputField>
                                <PaginationControls
                                    page={page}
                                    totalPages={totalPages}
                                    indexOfFirstItem={indexOfFirstItem}
                                    indexOfLastItem={indexOfLastItem}
                                    handlePageChange={handlePageChange}
                                    totalItems={totalRowCount}
                                    data={data}
                                    handleRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPage={pageSize}
                                ></PaginationControls>
                            </div>
                            <div className="d-flex justify-content-between align-items-center px-4 py-2">
                                {data.role === "SuperAdmin" ? (
                                        <div className="flex mr-3">
                                            <Select
                                                name="client"

                                                value={client === null ? null : {value: client, label: client.name}}
                                                options={clients.map(client => ({value: client, label: client.name}))}
                                                styles={styleSelect}
                                                classNamePrefix="select"
                                                onChange={(choice) => {
                                                    selectClient(choice.value);
                                                }}
                                            />
                                        </div>
                                    )
                                    :
                                    client ? (// Display the client name for Admins
                                        <div>{client.name}</div>
                                    ) : null
                                }
                                <div className="d-flex">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm ml-2"
                                        disabled={officesDeleteSelection.length === 0}
                                        onClick={() => setShowDeleteOfficeModal(true)}>
                                        {data.t.read("delete") + " (" + officesDeleteSelection.length + ")"}
                                    </button>
                                    <button
                                        className='btn btn-primary btn-sm ml-2 justify-content-end'
                                        type="button"
                                        onClick={openNewOfficeModal}>
                                        {data.t.read("add")}
                                    </button>
                                </div>
                            </div>
                            <div className="mb-2 card rounded-lg table-container">
                                <table className='table font-small m-0'>
                                    <thead>
                                    <tr>
                                        <th>
                                            <Checkbox disabled={!offices || !offices.length}
                                                      isChecked={isAllOfficesSelected()}
                                                      onChange={toggleAllOfficesSelected}/>
                                        </th>
                                        <th className="pointer col-2" onClick={() => sortTable("name")}>
                                            {data.t.read("name")}
                                            {sorting.find(s => s.id === "name") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "name").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th className="pointer col-6"
                                            onClick={() => sortTable("address")}>
                                            {data.t.read("address")}
                                            {sorting.find(s => s.id === "address") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "address").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th className="pointer col-3" onClick={() => sortTable("alias")}>
                                            Alias
                                            {sorting.find(s => s.id === "alias") &&
                                                <FontAwesomeIcon
                                                    className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"}
                                                    icon={sorting.find(s => s.id === "alias").desc ? faArrowDown : faArrowUp}/>}
                                        </th>
                                        <th className="pointer col-1">
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                </table>
                                <div className="tbody-container">
                                    <table className='table table-hover font-small'>

                                        <tbody>
                                        {
                                            offices.map((office) => {
                                                return <tr key={office._id?.$oid}>
                                                    <td>
                                                        <Checkbox isChecked={isOfficeSelected(office)}
                                                                  onChange={() => isOfficeSelected(office) ? setOfficesDeleteSelection(officesDeleteSelection.filter(x => x !== office._id?.$oid)) : setOfficesDeleteSelection([...officesDeleteSelection, office._id?.$oid])}/>
                                                    </td>
                                                    <td onClick={() => openUpdateOfficeModal(office)} className="col-2">
                                                        {office.name}
                                                    </td>
                                                    <td onClick={() => openUpdateOfficeModal(office)} className="col-6">
                                                        {office.address}
                                                    </td>
                                                    <td className="col-3" onClick={() => openUpdateOfficeModal(office)}>
                                                    <span className="comments-column">
                                                        {office.alias ? office.alias.join(', ') : ''}
                                                    </span>
                                                    </td>
                                                    <td className="col-1">
                                                        {<FontAwesomeIcon className="mr-2" icon={faPencilAlt}
                                                                          onClick={() => openUpdateOfficeModal(office)}
                                                                          type="button"/>}
                                                        {<FontAwesomeIcon onClick={() => {
                                                            setOfficesDeleteSelection([office._id?.$oid])
                                                            openDeleteOfficeModal()
                                                        }}
                                                                          icon={faWindowClose} type="button"
                                                                          style={{color: 'red'}}/>}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            emptyRows.map((_, idx) => (
                                                <tr key={`empty-${idx}`}>
                                                    <td colSpan="7">&nbsp;</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {showNewOfficeModal && (
                            <Modal title={data.t.read("new_office")}>
                                <form onSubmit={newOfficeSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">{data.t.read("name")}:</label>
                                        <input type="text" className="form-control" name="name" value={newOffice.name}
                                               onChange={(event) => setNewOffice({
                                                   ...newOffice,
                                                   name: event.target.value
                                               })} required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">{data.t.read("address")}:</label>
                                        <input type="text" className="form-control" name="address"
                                               value={newOffice.address}
                                               onChange={(event) => setNewOffice({
                                                   ...newOffice,
                                                   address: event.target.value
                                               })} required/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="zipcode">{data.t.read("zipcode")}:</label>
                                        <input type="text" className="form-control" name="zipcode"
                                               value={newOffice.zipcode}
                                               onChange={(event) => setNewOffice({
                                                   ...newOffice,
                                                   zipcode: event.target.value
                                               })} required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="alias">
                                            Alias:
                                            <button className='btn btn-primary btn-xs ml-2 pl-1 pr-1' type="button"
                                                    onClick={() => setNewOffice({
                                                        ...newOffice,
                                                        alias: [...newOffice.alias, ""]
                                                    })}
                                            >
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </button>
                                        </label>
                                        {newOffice.alias.map((alias, i) => (
                                            <div key={i}
                                                 className="d-flex justify-content-between form-control align-items-center mb-2 p-2">
                                                <input type="text" className="form-control border-0" name="alias"
                                                       value={alias} style={{backgroundColor: 'transparent'}}
                                                       onChange={(event) => setNewOffice({
                                                           ...newOffice,
                                                           alias: newOffice.alias.map((item, index) => index === i ? event.target.value : item)
                                                       })}/>
                                                <FontAwesomeIcon icon={faWindowClose} type="button" color={"red"}
                                                                 onClick={() => setNewOffice({
                                                                     ...newOffice,
                                                                     alias: newOffice.alias.filter((x, index) => index !== i)
                                                                 })}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <span className="d-flex justify-content-between">
                                        <button type="button" className={"btn btn-outline-dark"}
                                                onClick={cancelNewOfficeModal}>
                                            {data.t.read("cancel")}
                                        </button>
                                        <button type="submit" className="btn btn-primary">
                                             {data.t.read("add")}
                                        </button>
                                    </span>
                                </form>
                            </Modal>
                        )}
                        {showUpdateOfficeModal && (
                            <Modal title={data.t.read("office_update")}>
                                <form onSubmit={updateOfficeSubmit}>
                                    <div className="form-group">
                                        <label> {data.t.read("name")}:</label>
                                        <input type="text"
                                               className="form-control"
                                               name="name"
                                               value={updatedOfficeData.name}
                                               onChange={(event) => setUpdatedOfficeData({
                                                   ...updatedOfficeData,
                                                   name: event.target.value
                                               })}
                                               required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address"> {data.t.read("address")}:</label>
                                        <input type="text" className="form-control" name="address"
                                               value={updatedOfficeData.address}
                                               onChange={(event) => setUpdatedOfficeData({
                                                   ...updatedOfficeData,
                                                   address: event.target.value
                                               })}
                                               required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="zipcode"> {data.t.read("zipcode")}:</label>
                                        <input type="text" className="form-control" name="zipcode"
                                               value={updatedOfficeData.zipcode}
                                               onChange={(event) => setUpdatedOfficeData({
                                                   ...updatedOfficeData,
                                                   zipcode: event.target.value
                                               })}
                                               required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="alias">
                                            Alias:
                                            <button className='btn btn-primary btn-xs ml-2 pl-1 pr-1' type="button"
                                                    onClick={() => setUpdatedOfficeData({
                                                        ...updatedOfficeData,
                                                        alias: [...updatedOfficeData.alias, ""]
                                                    })}
                                            >
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </button>
                                        </label>
                                        {updatedOfficeData.alias.map((alias, i) => (
                                            <div key={i}
                                                 className="d-flex justify-content-between form-control align-items-center mb-2 p-2">
                                                <input type="text" className="form-control border-0" name="alias"
                                                       value={alias} style={{backgroundColor: 'transparent'}}
                                                       onChange={(event) => setUpdatedOfficeData({
                                                           ...updatedOfficeData,
                                                           alias: updatedOfficeData.alias.map((item, index) => index === i ? event.target.value : item)
                                                       })}
                                                />
                                                <FontAwesomeIcon className="ml-2 align-content-center"
                                                                 icon={faWindowClose} type="button" color={"red"}
                                                                 onClick={() => setUpdatedOfficeData({
                                                                     ...updatedOfficeData,
                                                                     alias: updatedOfficeData.alias.filter((x, index) => index !== i)
                                                                 })}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <span className="d-flex justify-content-between">
                                        <button type="button" className={"btn btn-outline-dark"}
                                                onClick={cancelUpdateOfficeModal}>
                                            {data.t.read("cancel")}
                                        </button>
                                        <button type="submit" className="btn btn-primary"
                                                disabled={!isOfficeUpdated(updatedOfficeData, originalOfficeData)}>
                                            {data.t.read("save")}
                                        </button>
                                    </span>
                                </form>
                            </Modal>
                        )}
                        {showDeleteOfficeModal && (
                            <Modal
                                title={data.t.read("office_deletion")}
                                okAction={confirmDeleteOffice}
                                cancelAction={cancelDeleteOfficeModal}
                                okText={data.t.read("delete")}
                                cancelText={data.t.read("cancel")}>
                                <p className='text-danger'>{data.t.read("delete")}?</p>
                            </Modal>
                        )}
                        {
                            errorMessage && (
                                <Modal title="Attention" okText={"OK"} okAction={() => setErrorMessage(null)}>
                                    <p style={{whiteSpace: 'pre-line'}} className='text-danger'>{errorMessage}</p>
                                </Modal>)
                        }
                        {
                            successMessage && (
                                <Modal title="Attention" okText={"OK"} okAction={() => setSuccessMessage(null)}>
                                    <p style={{whiteSpace: 'pre-line'}}>{successMessage}</p>
                                </Modal>

                            )
                        }
                    </div>
                    :
                    <div>
                        <div className="d-flex loading-container w-100">
                            <FontAwesomeIcon className="infinite-rotate" icon={faSpinner}/>
                        </div>
                    </div>
                }
            </div>
        </div>)


}


export default inject('data')(observer(AdminOffices));