import React, { Component } from 'react';
import { inject } from "mobx-react";
import Chart from 'chart.js/auto';

export default inject("data")(class GraphDoughnut extends Component  {
    constructor(props) {
        super(props);

        this.chart = null;
    }

    componentDidMount() {
        this.refreshGraph();
    }

    componentDidUpdate() {
        this.refreshGraph();
    }

    refreshGraph() {
        const config = {
            type: 'doughnut',
            data:  {
                labels: this.props.labels,
                datasets: [
                    {
                    label: this.props.label ?? this.props.data.t.read("invoices"),
                    data: this.props.dataset,
                    fill: false,
                    borderColor: this.props.color ?? "hsl(38, 100%, 48%)",
                    backgroundColor: this.props.color ?? "#ffcf9f",
                    }
                ]
            },
            options: {
                cutout: 130,
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false
                    },
                },
                interaction: {
                    intersect: false,
                },
                animation: {
                    duration: 0
                },
            },
        };

        if (this.chart != null) {
            this.chart.data.datasets = config.data.datasets
            this.chart.update();
        }
        else {
            this.chart = new Chart(document.querySelector("#" + this.props.id), config);
        }
    }

    render() {
        return  <div style={{height:"100%"}} className="position-relative">
                    <canvas id={this.props.id} style={{maxWidth:"100%"}}></canvas>
                    <div style={{position:"absolute",zIndex:1, width:"100%", height:"100%", textAlign:"center",top:"53%",fontSize:"90%",lineHeight:"90%"}}>
                        <div style={{ fontWeight:600, fontSize:"150%", marginBottom:"10px" }}>
                            {this.props.label} {this.props.total}
                        </div>
                        <div style={{ fontSize:"0.9em", opacity:0.8 }}>
                            {this.props.totalLabel}
                        </div>
                    </div>
                </div>
    }
});