import React, { Component } from 'react';
import { inject } from "mobx-react";
import Chart from 'chart.js/auto';

export default inject("data")(class GraphLine extends Component  {
    constructor(props) {
        super(props);

        this.chart = null;
    }

    componentDidMount() {
        this.refreshGraph();
    }

    componentDidUpdate() {
        this.refreshGraph();
    }

    refreshGraph() {
        let labels = this.props.labels;
        let preventTrim = this.props.preventTriming ?? false;

        if (!preventTrim) {
            labels = this.props.labels.slice(0, this.props.dataset.length);
        }

        const config = {
            type: 'line',
            data:  {
                labels: labels,
                datasets: [
                    {
                        label: this.props.label ?? this.props.data.t.read("invoices"),
                        data: this.props.dataset,
                        fill: false,
                        cubicInterpolationMode: this.props.hardLine ? null : 'monotone',
                        tension: this.props.hardLine ? null : 0.4,
                        borderColor: this.props.colorFunction ?? this.props.color ?? "hsl(38, 100%, 48%)",
                    },
                    {
                        label: this.props.label ?? this.props.data.t.read("invoices"),
                        data: this.props.secondaryDataSet,
                        fill: false,
                        cubicInterpolationMode: this.props.hardLine ? null : 'monotone',
                        tension: this.props.hardLine ? null : 0.4,
                        borderColor: this.props.colorFunctionSec ?? this.props.colorSec ?? "#ddd",
                        borderDash: [5,5],
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: false
                    },
                },
                interaction: {
                    intersect: false,
                },
                animation: {
                    duration: 0
                },
                scales: {
                    y: {
                        min:0,
                        display: this.props.showYAxis ? true : false,
                        title: {
                            display: false,
                            text: 'Value'
                        },
                        grid: {
                            drawBorder: true,
                            lineWidth: this.props.showYGrid ? 0.5 : 0,
                        }
                    },
                    x: {
                        grid: {
                            drawBorder: true,
                            lineWidth: this.props.showYGrid ? 0.5 : 0,
                        }
                    }
                }
            },
        };

        if (this.chart != null) {
            this.chart.data.datasets = config.data.datasets
            this.chart.update();
        }
        else {
            this.chart = new Chart(document.querySelector("#" + this.props.id), config);
        }
    }

    render() {
        return  <canvas id={this.props.id} style={{maxWidth:"100%"}}></canvas>
    }
});