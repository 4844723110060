/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import { observer, inject } from 'mobx-react';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from "../components/checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/modal";
import availableColumns from "../utils/available-columns";
import PlCustomer from "../components/pl-customer";
import Select from 'react-select';
import { fetch } from '../utils/api-wrapper';

const Paiements = ({ data }) => {
    const source = axios.CancelToken.source();
    

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            //bgColor: "#F39800",
            // border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };

    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [sorting, setSorting] = useState([]);
    const [filters, setFilters] = useState([]);
    const [globalfilter, setGlobalfilter] = useState("");
    const [dirtyGlobalfilter, setDirtyGlobalfilter] = useState("");
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSendApprovalConfirmation, setShowSendApprovalConfirmation] = useState(false);
    const [formSupplierBankingInfos, setFormSupplierBankingInfos] = useState({});
    const [processingAccounts, setProcessingAccounts] = useState(null);
    const [selectedProcessingAccount, setSelectedProcessingAccount] = useState(null);

    const showFormBankingInfos = (supplier_info) => {
        setFormSupplierBankingInfos({ supplier: supplier_info });
    }

    const fetchInvoices = () => {
        let start = page * pageSize;

        if (globalfilter.length !== 0 || filters.length !== 0)
            start = 0;

        const searchData = `?start=${encodeURIComponent(start)}&page_size=${encodeURIComponent(pageSize)}&globalfilter=${encodeURIComponent(globalfilter ?? '')}&filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;

        fetch(  `/payments/suppliers/${searchData}`, "GET", null, (response) => {
            setSuppliers(response.data.suppliers);
            setLoading(false);
            setTotalRowCount(response.data.meta.totalRowCount);
        },
            null, false, source.token);
    }

    useEffect(() => {
        fetchInvoices();
    }

        , [page, sorting, filters, globalfilter, pageSize]);

    useEffect(() => {
        fetChProcessingAccount();
    }
        , [])

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLoading(true);
        setPageSize(parseInt(event.target.value, 10));
    };

    const handlecheckboxchange = (selected, supplier) => {
        const supplierIndex = selectedInvoices.findIndex(supp => supp.supplier_number === supplier.supplier_number);

        if (supplierIndex === -1) {
            supplier["invoices"] = [selected];
            setSelectedInvoices([...selectedInvoices, supplier]);
            return;
        }

        const shallow = [...selectedInvoices];
        const index = shallow[supplierIndex].invoices.findIndex(invoice => invoice._id.$oid === selected._id.$oid);

        if (index === -1)
            shallow[supplierIndex].invoices.push(selected);
        else
            shallow[supplierIndex].invoices = shallow[supplierIndex].invoices.filter(invoice => invoice._id.$oid !== selected._id.$oid);

        setSelectedInvoices(shallow.filter(supp => supp.invoices.length > 0));
    }

    const handleAllcheckboxchange = (supplier) => {
        const supplierIndex = selectedInvoices.findIndex(supp => supp.supplier_number === supplier.supplier_info[0].supplier_number);

        if (supplierIndex === -1) {
            const supp = supplier.supplier_info[0];
            supp["invoices"] = [...supplier.documents];
            setSelectedInvoices([...selectedInvoices, supp]);
            return;
        }

        const shallow = [...selectedInvoices];

        if (shallow[supplierIndex].invoices.length !== supplier.documents.length)
            shallow[supplierIndex].invoices = [...supplier.documents];
        else
            shallow[supplierIndex].invoices = [];

        setSelectedInvoices(shallow.filter(supp => supp.invoices.length > 0));
    }

    const isSelected = (supplier, selected) => {
        const supplierIndex = selectedInvoices.findIndex(supp => supp.supplier_number === supplier.supplier_number);

        if (supplierIndex === -1)
            return false;

        const index = selectedInvoices[supplierIndex].invoices.findIndex(invoice => invoice._id.$oid === selected._id.$oid);

        if (index === -1)
            return false;

        return true;
    }

    const isAllSelected = (supplier) => {
        const supplierIndex = selectedInvoices.findIndex(supp => supp.supplier_number === supplier.supplier_info[0].supplier_number);

        if (supplierIndex === -1)
            return false;

        if (supplier.documents.length !== selectedInvoices[supplierIndex].invoices.length)
            return false;

        return true;
    }

    const isAllPageSelected = () => {
        const index = suppliers.filter(supp => supp.supplier_info[0].pl_payment_method).findIndex(supplier => !isAllSelected(supplier));
        return index === -1;
    }

    const handleAllPagecheckboxchange = () => {
        const shallow = [...selectedInvoices];
        if (isAllPageSelected()) {
            setSelectedInvoices(shallow.filter(supp =>
                suppliers.findIndex(supplier => supplier.supplier_info[0].supplier_number === supp.supplier_number) === -1
            ));
        }
        else {
            suppliers.filter(supp => supp.supplier_info[0].pl_payment_method).forEach(supplier => {
                if (!isAllSelected(supplier)) {
                    const supplierIndex = shallow.findIndex(supp => supp.supplier_number === supplier.supplier_info[0].supplier_number);

                    if (supplierIndex === -1) {
                        const supp = supplier.supplier_info[0];
                        supp["invoices"] = [...supplier.documents];
                        shallow.push(supp);
                        return;
                    }
                    else
                        shallow[supplierIndex].invoices = [...supplier.documents];
                }
            }
            );

            setSelectedInvoices([...shallow]);
        }
    }

    const sendForPayment = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("suppliers", JSON.stringify(selectedInvoices));
        formData.append("processing_account", JSON.stringify(selectedProcessingAccount.value));

        fetch(  `/payments/request/`, "POST", formData,
            (response) => {
                setShowSendApprovalConfirmation(false);
                setSelectedInvoices([]);
                //setSelectedProcessingAccount(null);
                fetchInvoices();
            },
            (error) => {
                setShowSendApprovalConfirmation(false);
                setErrorMessage(data.t.read("internal_saving_error"));
            }, false, source.token);
    }

    const fetChProcessingAccount = () => {
        fetch(  `/payments/processing_account/pl`, "GET", null, (response) => {
            setProcessingAccounts(response.data.processing_account);
        },
            null, false, source.token);
    }

    const sum = (accumulator, currentValue) => {
        // Convert currentValue to a number using parseFloat
        const numberValue = parseFloat(currentValue);
        // Check if numberValue is a valid number
        if (!isNaN(numberValue)) {
            // Add the numberValue to the accumulator
            accumulator += numberValue;
        }
        return accumulator;
    };

    const total_with_tax = (invoices) => invoices.map(inv => inv.json?.TotalWithTax).reduce(sum, 0);
    const total = (invoices) => invoices.map(inv => inv.json?.Total).reduce(sum, 0);

    const total_selected = (selected) => {
        let total = 0;
        selected.map(supplier => total += total_with_tax(supplier.invoices))
        return total;
    }
    const count_selected = (selected) => {
        let total = 0;
        selected.map(supplier => total += supplier.invoices.length)
        return total;
    }

    return !loading ? <div className="card">
        <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
                <input type="text" className="form-control search-field" value={dirtyGlobalfilter} onChange={e => { setDirtyGlobalfilter(e.target.value) }} onKeyUp={e => e.key === "Enter" && setGlobalfilter(dirtyGlobalfilter)} />
                <TablePagination className="pagination" component="div" count={totalRowCount} page={page} onPageChange={handleChangePage} rowsPerPage={pageSize} onRowsPerPageChange={handleChangeRowsPerPage} />
            </div>
            <div className="d-flex justify-content-between">
                <div className="p-2">
                    <span className="mr-2 font-weight-bold">{data.t.read("send_for_payment_by")}</span>
                    <span className="font-italic">{data.firstName} {data.lastName}</span>
                </div>
                <div className="p-2">
                    <span className="mr-2">
                        {data.t.read("amount_total")} :
                    </span>
                    <span className="font-weight-bold">
                        {total_selected(selectedInvoices).toLocaleString(`${data.t.lang}-CA`, { style: 'currency', currency: "CAD" })}
                    </span>
                </div>
                <div className="row col-4">
                    <div className="col-6">
                        <Select
                            name="processingAccount"
                            options={processingAccounts && processingAccounts.map(p => ({ value: p, label: p.name }))}
                            styles={styleSelect}
                            classNamePrefix="select"
                            className= "btn-primary-shadow"
                            isLoading={processingAccounts === null}
                            value={selectedProcessingAccount}
                            onChange={(choice) => setSelectedProcessingAccount(choice)}
                        />
                    </div>
                    <div>
                        <button type="button" disabled={!selectedProcessingAccount} onClick={() => setShowSendApprovalConfirmation(true)} className="btn btn-primary btn-primary-shadow">
                            {data.t.read("request_approval")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="p-2">
                    <Checkbox isChecked={isAllPageSelected} onChange={handleAllPagecheckboxchange} />
                    <label className="mr-3 ml-1">
                        {data.t.read("select_all_invoices")}
                    </label>
                    <Checkbox isChecked={isAllPageSelected} onChange={handleAllPagecheckboxchange} />
                    <label className="mr-3 ml-1">
                        {data.t.read("select_all_due_invoices")}
                    </label>
                </div>
            </div>
            {
                suppliers.map(supplier => <div key={`${supplier._id}-header`} className="card m-0 p-0 rounded-lg ">
                    <div  className="card-header d-flex justify-content-between p-0">
                        <div className="p-2">
                            {supplier.supplier_info[0].names[0]}
                        </div>
                        {
                            !supplier.supplier_info[0].pl_payment_method && <div>
                                <span className="text-warning mr-3">
                                    {data.t.read("supplier_missing_banking_infos")}
                                </span>
                                <button className="btn btn-outline-secondary p-1 mr-3 ml-3 mt-1 mb-1" onClick={() => showFormBankingInfos(supplier.supplier_info[0])} >
                                    {data.t.read("add_new")}
                                </button>
                            </div>
                        }
                    </div>
                    <div className="card-body m-0 p-0">
                        <div className="table m-0 p-0">
                            <table className="table table-hover m-0 p-0">
                                <thead>
                                    <tr>
                                        <th><Checkbox key={`${supplier._id}-checkbox`} isChecked={isAllSelected(supplier)} onChange={() => handleAllcheckboxchange(supplier)} disabled={!supplier.supplier_info[0].pl_payment_method} /></th>
                                        {availableColumns.map((availableColumn) => (
                                            <th key={`header-${availableColumn.displayName}}`}>{data.t.read(`invoice.${availableColumn.displayName}`)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {supplier.documents.map(inv => <tr key={`${inv._id.$oid}-list`}>
                                        <td key={`${inv._id.$oid}-checkbox-list`}> <Checkbox isChecked={isSelected(supplier.supplier_info[0], inv)} onChange={() => handlecheckboxchange(inv, supplier.supplier_info[0])} key={`${inv._id.$oid}-form-checkbox-list`} disabled={!supplier.supplier_info[0].pl_payment_method} /></td>
                                        {availableColumns.map(availableColumn => <td key={`${inv._id.$oid}-${availableColumn.displayName}-value`}>{availableColumn.evaluate(inv, data)}</td>)}
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        selectedInvoices.filter(supp => supp.supplier_number === supplier.supplier_info[0].supplier_number)
                            .map(supp => <div key={`${supplier._id}-footer`} className="bg-primary text-white card-footer">
                                {
                                    <div className="d-flex justify-content-around ">
                                        <div>
                                            <span> {data.t.read("amount_selected_for_payment")}</span>
                                        </div>
                                        <div>
                                            <span> {data.t.read("invoice.total")} : </span>
                                            <span> {total(supp.invoices).toLocaleString(`${data.t.lang}-CA`, { style: 'currency', currency: "CAD" })} </span>
                                        </div>
                                        <div>
                                            <span> {data.t.read("invoice.total_with_tax")} : </span>
                                            <span> {total_with_tax(supp.invoices).toLocaleString(`${data.t.lang}-CA`, { style: 'currency', currency: "CAD" })}  </span>
                                        </div>
                                    </div>
                                }
                            </div>
                            )
                    }
                </div>
                )
            }
        </div>
        {
            showSendApprovalConfirmation &&
            <Modal title={data.t.read("request_approval")} okAction={() => { sendForPayment() }} okText={data.t.read("confirm")} cancelAction={() => setShowSendApprovalConfirmation(false)} cancelText={data.t.read("cancel")}>
                <div>
                    {data.t.read("request_approval_confirmation").replace("{count}", count_selected(selectedInvoices)).replace("{total}", total_selected(selectedInvoices).toLocaleString(`${data.t.lang}-CA`, { style: 'currency', currency: "CAD" }))}
                </div>
            </Modal>
        }
        {
            formSupplierBankingInfos.supplier &&
            <PlCustomer formSupplierBankingInfos={formSupplierBankingInfos} setFormSupplierBankingInfos={setFormSupplierBankingInfos} fetcher={fetchInvoices} />
        }
    </div>
        :
        <>
            {errorMessage ?
                <Modal title={data.t.read("error")} okAction={() => { setErrorMessage(null); setLoading(false) }} okText={"ok"}>
                    <div className="text-danger">
                        {errorMessage}
                    </div>
                </Modal>
                :
                <div className="loading-container">
                    <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
                </div>
            }
        </>
}
export default inject('data')(observer(Paiements));
