import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import FormTextField from './form-textfield';
import { fetch } from '../utils/api-wrapper';


export default inject("data")(observer(class SupplierUnitsCodes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            codeUnitList: null,
            supplierId: null,
            supplierName: "",
            codeUnitIdx: 0
        }
    }

    componentDidMount() {
        fetch(  "/admin-client-" + this.props.type + "/" + this.props.clientId, "GET", {},
            response => {
                this.setState({
                    codeUnitList: response.data
                }, () => {
                    if (response.data.length > 0) {
                        this.refreshList();
                    }
                })
            })
    }

    refreshList() {
        fetch(  "/supplier-" + this.props.type + "/" + this.props.clientId + "/assoc", "GET", {},
            response => {
                this.setState({
                    list: response.data
                })
            })
    }

    deleteElement(id) {
        fetch(  "/supplier-" + this.props.type + "/" + id, "DELETE", {}, response => {
            this.refreshList();
        })
    }

    addCode() {
        if (this.state.supplierId != null) {
            let data = new FormData();
            let sel = this.state.codeUnitList[this.state.codeUnitIdx];
            data.append("id_client", this.props.clientId);
            data.append("id_supplier", this.state.supplierId);
            data.append(this.props.type === "codes" ? "code" : "unit", this.props.type === "codes" ? sel.code : sel.unit);

            fetch(  "/admin-supplier-" + this.props.type, "POST", data,
                response => {
                    this.setState({
                        supplierId: null,
                        supplierName: "",
                        text: "",
                    }, () => {
                        this.refreshList();
                    })
                },
                response => {

                })
        }
    }

    render() {
        return <div>
            {
                this.state.codeUnitList == null ?
                    <div className='font-italic'>
                        Chargement en cours...
                    </div>
                    :
                    this.state.codeUnitList.length > 0 ?
                        <div>
                            <div className='font-xsmall mb-2'>
                                <span className=''>
                                    {this.props.type === "codes" ? "Code " : "Unité "}
                                    {this.props.type === "codes" ? this.state.codeUnitList[this.state.codeUnitIdx].code : this.state.codeUnitList[this.state.codeUnitIdx].unit}
                                    {" : "}
                                </span>
                                {this.state.codeUnitList[this.state.codeUnitIdx].desc}
                            </div>
                            <table className='table table-striped table-sm font-small'>
                                <thead>
                                    <tr>
                                        <th>{this.props.type === "codes" ? "Code" : this.props.data.t.read("client_table.units_offices")}</th>
                                        <th>{this.props.data.t.read("supplier_name")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <select className='form-control form-control-sm' style={{ maxWidth: "100px" }} value={this.state.codeUnitIdx} onChange={e => this.setState({ codeUnitIdx: e.target.value })}>
                                                {
                                                    this.state.codeUnitList.map((elem, idx) => {
                                                        return <option key={idx} value={idx}>
                                                            {this.props.type === "codes" ? elem.code : elem.unit}
                                                        </option>
                                                    })
                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <FormTextField format="data" value={this.state.supplierName} hideNA={true} hideActionButtons={true} hideAddOperation={true} searchURL="/suppliers/search/" searchCallback={(sup, typedText) => this.setState({ supplierId: sup._id, supplierName: sup.name })} />
                                        </td>
                                        <td className='text-center'>
                                            <button className='btn btn-info btn-sm' onClick={() => this.addCode()}>{this.props.data.t.read("add")}</button>
                                        </td>
                                    </tr>
                                    {
                                        this.state.list.map(element => {
                                            return <tr key={element._id}>
                                                <td>{element.code ?? element.unit}</td>
                                                <td>{element.supplier_name}</td>
                                                <td className='text-center'>
                                                    <button className='btn btn-danger btn-sm' onClick={() => this.deleteElement(element._id)}>{this.props.data.t.read("delete")}</button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className='font-italic'>
                            {this.props.data.t.read("no_existing_code_or_unit_for_client")}                        </div>
            }
        </div>
    }
}));