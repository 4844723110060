import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const PaginationControls = ({
                                page,
                                totalPages,
                                indexOfFirstItem,
                                indexOfLastItem,
                                handlePageChange,
                                totalItems,
                                data,
                                rowsPerPage,
                                handleRowsPerPageChange
                            }) => {
    return (
        <div className='d-flex justify-content-between p-2  align-items-center '>

            {/* Rows Per Page Selector */}
            <div className="mr-3">
                <span className='mr-2'>{data.t.read("rows_per_page")}</span>
                <select
                    value={rowsPerPage}
                    onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
                    className='form-control d-inline-block'
                    style={{ width: '80px' }}>
                    {[5, 10, 20, 50].map((size) => (
                        <option key={size} value={size}>
                            {size}
                        </option>
                    ))}
                </select>
            </div>


            {/* Pagination Information */}
            <div className="mr-1">
                <span className='font-italic'>
                    {indexOfFirstItem + 1}-{indexOfLastItem + 1} {data.t.read('rule.of')}
                </span>
                <span className='p-1 text-primary'> {totalItems} </span>
            </div>


            {/* Pagination Buttons */}
            <div>
                <button
                    className='btn btn-sm'
                    disabled={page === 1}
                    type='button'
                    onClick={() => handlePageChange(page - 1)}
                >
                    <FontAwesomeIcon className='fa-xs' icon={faChevronLeft}/>
                </button>
                <span className='active text-primary'>
                    <span className='p-2'>{page}</span>
                </span>
                <button
                    className='btn btn-sm'
                    type='button'
                    disabled={page >= totalPages}
                    onClick={() => handlePageChange(page + 1)}
                >
                    <FontAwesomeIcon className='fa-xs' icon={faChevronRight}/>
                </button>
            </div>
        </div>
    );
};

export default PaginationControls;