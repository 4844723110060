import React, { useEffect, useState } from 'react';
import { observer, inject } from "mobx-react";
import Modal from './../../components/modal';
import SupplierUnitsCodes from '../../components/supplier-units-codes';
import MoneyInput from '../../components/money-input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faSpinner, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AdminNavigationBar from "../../components/admin-navigation-bar";
import { fetch } from '../../utils/api-wrapper';

const AdminClient = ({ data }) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [codeClientId, setCodeClientId] = useState(null);
    const [unitClientId, setUnitClientId] = useState(null);
    const [showImportSuppliers, setShowImportSuppliers] = useState(false);
    const [importFile, setImportFile] = useState(null);
    const [components, setComponents] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [showClientModal, setShowClientModal] = useState(false);
    const [updatedClient, setUpdatedClient] = useState({});
    const [clientToDelete, setClientToDelete] = useState(null);
    const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
    const [showSupplierCodesModal, setShowSupplierCodesModal] = useState(false);
    

    useEffect(() => {
        refreshClients();
        fetchComponents();
    }, []);

    const refreshClients = () => {
        fetch(  "/clients/", "GET", {},
            response => {
                let clients = response.data.clients;
                setClients(clients);
                setLoading(false);
            },
            () => {
                setErrorMessage(data.t.read(data.t.read("supplier_error_loading_clients")))
                setLoading(false);
            }
        );
    };

    const fetchComponents = () => {
        fetch(  "/clients/components", "GET", {},
            response => {
                let components = response.data;
                setComponents(components);
            },
            () => {
            }
        );
    };

    const handleClientSubmit = (e) => {
        e.preventDefault();
        setShowClientModal(false);
        if (updatedClient._id) {
            updateClient();
        } else {
            createClient();
        }
    };

    const createClient = () => {
        const formData = new FormData();
        formData.append('client', JSON.stringify(updatedClient));
        fetch("/clients/", "POST", formData,
            () => {
                setSuccessMessage(data.t.read("client_created_successfully"));
                refreshClients();
            },
            () => {
                setErrorMessage(data.t.read("failed_to_create_client"));
            }
        );
    };

    const updateClient = () => {
        const updatedClientWithoutId = { ...updatedClient };
        const clientId = updatedClientWithoutId._id;
        delete updatedClientWithoutId._id;
        const formData = new FormData();
        formData.append('client', JSON.stringify(updatedClientWithoutId));

        fetch(  `/clients/${clientId}`, "PUT", formData,
            () => {
                refreshClients();
                setSuccessMessage(data.t.read("client_updated_successfully"));
            },
            () => {
                setErrorMessage(data.t.read("failed_to_update_client"));
            }
        );
    };

    const openNewClientModal = () => {
        setUpdatedClient({
            name: "",
            emails: "",
            supplier_codes: [],
            supplier_units: [],
            users: [],
            config: {
                type: "legal",
                engine: "default",
                frontend_invoice: {
                    hide_na_fields: true,
                    hide_cost_details_table: false,
                    hide_work_date: false,
                    hide_office_in_gl_details_table: false,
                    hide_due_date: false,
                },
                components: [],
                variance: null,
                roles: ["SuperAdmin", "Admin", "Manager", "Clerk"],
                invoice_exporter: {
                    group_invoice_by: "",
                    export_supplier: true,
                    detail_on_multiple_line: false,
                    column_amount: 0,
                    re_export_supplier: false,
                    csv_line_template_invoice: "",
                    csv_line_template_supplier: "",
                    taxes_gl: "",
                    separator: ";",
                    decimal_separator: ".",
                    details_column_amount: 0
                },
                fiscal_year_start: "",
                cost_type: "",
                cost_type_nin: "",
                logo: ""
            },
        });
        setShowClientModal(true);
    };

    const openUpdateClientModal = (client) => {
        setUpdatedClient({ ...client });
        setShowClientModal(true);
    };

    const cancelClientModal = () => {
        setUpdatedClient({});
        setShowClientModal(false);
    };

    const deleteClient = () => {
        if (clientToDelete) {
            fetch(`/clients/`, "DELETE", {"client_ids": [clientToDelete._id]},
                () => {
                    refreshClients();
                    setClientToDelete(null);
                    setShowDeleteClientModal(false);
                    setSuccessMessage(data.t.read("success_deleting_client"));
                },
                () => {
                    setErrorMessage(data.t.read("failed_to_delete_client"));
                }
            );
        }
    };

    const openDeleteClientModal = (client) => {
        setClientToDelete(client);
        setShowDeleteClientModal(true);
    };

    const cancelDeleteClientModal = () => {
        setClientToDelete(null);
        setShowDeleteClientModal(false);
    };

    const updateField = (fieldName, value, subField = null, subSubField = null) => {
        setUpdatedClient(prevState => {
            let updatedClientCopy = { ...prevState };

            if (subSubField != null) {
                if (!updatedClientCopy[fieldName][subField]) {
                    updatedClientCopy[fieldName][subField] = {};
                }
                updatedClientCopy[fieldName][subField][subSubField] = value;
            } else if (subField == null) {
                updatedClientCopy[fieldName] = value;
            } else {
                updatedClientCopy[fieldName][subField] = value;
            }
            return updatedClientCopy;
        });
    };

    const updateUnit = (idx, fieldName, value) => {
        setUpdatedClient(prevState => {
            const supplier_units = [...prevState.supplier_units];
            supplier_units[idx] = { ...supplier_units[idx], [fieldName]: value };
            return { ...prevState, supplier_units };
        });
    };

    const removeUnit = (idx) => {
        setUpdatedClient(prevState => {
            const supplier_units = [...prevState.supplier_units];
            supplier_units.splice(idx, 1);
            return { ...prevState, supplier_units };
        });
    };

    const addUnit = () => {
        setUpdatedClient(prevState => {
            const supplier_units = prevState.supplier_units ? [...prevState.supplier_units] : [];
            supplier_units.push({ unit: "", desc: "" });
            return { ...prevState, supplier_units };
        });
    };

    const toggleFrontendSection = (component) => {
        setUpdatedClient(prevState => {
            let components = prevState.config.components ? [...prevState.config.components] : [];
            let idx = components.findIndex(comp => comp.id === component._id);

            if (idx === -1) {
                components.push({ name: component.name, id: component._id });
            } else {
                components.splice(idx, 1);
            }

            return { ...prevState, config: { ...prevState.config, components } };
        });
    };

    const prepareImportSuppliers = (f) => {
        if (f.length > 0) {
            setImportFile(f[0]);
        }
    };

    const processImportSuppliers = () => {
        if (importFile != null) {
            const data = new FormData();
            data.append("csv", importFile);
            data.append("id_client", codeClientId);

            fetch(  "/suppliers/client-import", "POST", data,
                response => {
                    setShowImportSuppliers(false);
                    setImportFile(null);
                    refreshClients();
                },
                null, true
            );
        }
    };

    const updateVariance = (variance) => {
        setUpdatedClient(prevState => ({
            ...prevState,
            config: { ...prevState.config, variance }
        }));
    };

    const updateCostType = (cost_type) => {
        setUpdatedClient(prevState => ({
            ...prevState,
            config: { ...prevState.config, cost_type }
        }));
    };

    const updateCostTypeNIN = (cost_type_nin) => {
        setUpdatedClient(prevState => ({
            ...prevState,
            config: { ...prevState.config, cost_type_nin }
        }));
    };

    const updateLogo = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUpdatedClient(prevState => ({
                    ...prevState,
                    config: { ...prevState.config, logo: reader.result }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const toggleExporterOption = (option) => {
        setUpdatedClient(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                invoice_exporter: {
                    ...prevState.config.invoice_exporter,
                    [option]: !prevState.config.invoice_exporter[option]
                }
            }
        }));
    };

    const changeExporterValues = (field, e) => {
        const value = e.target ? e.target.value : e;
        setUpdatedClient(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                invoice_exporter: {
                    ...prevState.config.invoice_exporter,
                    [field]: value
                }
            }
        }));
    };

    const changeColAmountExporter = (e) => {
        const value = parseInt(e.target.value, 10);
        setUpdatedClient(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                invoice_exporter: {
                    ...prevState.config.invoice_exporter,
                    column_amount: value
                }
            }
        }));
    };

    const getInvoiceExporterOption = (option) => {
        return updatedClient.config?.invoice_exporter?.[option] || false;
    };

    const getColumnValueExporter = (n) => {
        const template = updatedClient.config?.invoice_exporter?.csv_line_template_invoice || "";
        const arr = template.split(";");
        const value = arr[n];
        if (value && value.startsWith("HardCoded:")) {
            return "HardCoded";
        }
        return value || "";
    };

    const getHardCodedValueExporter = (n) => {
        const template = updatedClient.config?.invoice_exporter?.csv_line_template_invoice || "";
        const arr = template.split(";");
        const value = arr[n];
        if (value && value.startsWith("HardCoded:")) {
            return value.substring("HardCoded:".length);
        }
        return "";
    };

    const changeColumnValueExporter = (value, n) => {
        setUpdatedClient(prevState => {
            const template = prevState.config.invoice_exporter.csv_line_template_invoice || "";
            const arr = template.split(";");
            arr[n] = value;
            const newTemplate = arr.join(";");
            return {
                ...prevState,
                config: {
                    ...prevState.config,
                    invoice_exporter: {
                        ...prevState.config.invoice_exporter,
                        csv_line_template_invoice: newTemplate
                    }
                }
            };
        });
    };

    const openSupplierCodeModal = (client) => {
        setCodeClientId(client._id)
        setShowSupplierCodesModal(true)
    }

    const closeSupplierCodesModal = () => {
        setCodeClientId(null)
        setShowSupplierCodesModal(false)
    }

    // Pagination logic
    const emptyRowsCount = Math.max(0, pageSize - clients.length);
    const emptyRows = Array(emptyRowsCount).fill({});

    return (
        <div className="card pl-4 pr-4 pb-3 rounded-lg">
            <AdminNavigationBar data={data} adminPage={"clients"}></AdminNavigationBar>
            <div>
                {!loading ?
                    <div>
                        <div>
                            <div className="d-flex justify-content-end align-items-center px-4 py-2">
                                <div className="d-flex">
                                    <button onClick={openNewClientModal} type="button"
                                            className='btn btn-primary btn-sm ml-2'>
                                        {data.t.read("add")}
                                    </button>
                                </div>
                            </div>
                            <div className="mb-2 card rounded-lg table-container">
                                <div className='mr-2'>
                                    <table className='table font-small m-0'>
                                        <thead>
                                        <tr>
                                            <th className="col-1">{data.t.read("client_table.name")}</th>
                                            <th className="col-1">{data.t.read("client_table.number_of_users")}</th>
                                            <th className="col-2">{data.t.read("client_table.created_on")}</th>
                                            <th className="col-2">{data.t.read("client_table.last_modified_on")}</th>
                                            <th className="text-center col-2">{data.t.read("client_table.actions")}</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="tbody-container">
                                    <table className='table font-small table-hover '>
                                        <tbody>
                                        {
                                            clients.map(client => {
                                                return <tr key={client._id}>
                                                    <td className="col-1" onClick={() => openUpdateClientModal(client)}>{client.name}</td>
                                                    <td className="col-1 text-center" onClick={() => openUpdateClientModal(client)}>{client.users_count}</td>
                                                    <td className="col-2" onClick={() => openUpdateClientModal(client)}>{client.created_at ?  Intl.DateTimeFormat(`${data.t.lang}-US`,{year: 'numeric',  month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,  }).format(new Date(client.created_at?.$date)) : ""}</td>
                                                    <td className="col-2" onClick={() => openUpdateClientModal(client)}>{client.updated_at ? Intl.DateTimeFormat(`${data.t.lang}-US`,{year: 'numeric',  month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,  }).format(new Date(client.updated_at?.$date)) : ""}</td>
                                                    <td className='col-2'>
                                                        <div className='d-flex justify-content-end mr-2'>

                                                            <button className='btn btn-info btn-sm ml-1'
                                                                    onClick={() => openSupplierCodeModal(client)}>{data.t.read("client_table.supplier_codes")}</button>
                                                            <button className='btn btn-secondary btn-sm ml-1'
                                                                    onClick={() => setUnitClientId(client._id)}>{data.t.read("client_table.units_offices")}</button>
                                                            <FontAwesomeIcon className="mx-2 my-auto"
                                                                             icon={faPencilAlt}
                                                                             onClick={() => openUpdateClientModal(client)}
                                                                             type="button"/>
                                                            <FontAwesomeIcon className="mx-2 my-auto" icon={faWindowClose} type="button"
                                                                             onClick={() => openDeleteClientModal(client)}
                                                                             style={{ color: 'red' }} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            emptyRows.map((_, idx) => (
                                                <tr key={`empty-${idx}`}>
                                                    <td colSpan="7">&nbsp;</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {showDeleteClientModal && (
                            <Modal
                                title={(clients.find(c => c._id === clientToDelete._id)).name}
                                okAction={deleteClient}
                                cancelAction={cancelDeleteClientModal}
                                okText={data.t.read("delete")}
                                cancelText={data.t.read("cancel")}>
                                <p className='text-danger'>{data.t.read("confirm_delete_client")}</p>
                            </Modal>
                        )}
                        {
                            showClientModal && (
                                <form method="post" onSubmit={handleClientSubmit}>
                                    <Modal width="60vw" title={updatedClient.name || data.t.read("add_client")}
                                           okIsSubmit={true} cancelAction={cancelClientModal}
                                           okText={data.t.read("save")}
                                           cancelText={data.t.read("cancel")}>
                                        <div className='mb-2'>
                                            <input required placeholder={data.t.read("client_table.client_name")}
                                                   className='form-control' type="text" value={updatedClient.name || ""}
                                                   onChange={(e) => updateField("name", e.target.value)}
                                            />
                                        </div>
                                        <div className='mb-2'>
                                            <input placeholder={data.t.read("client_table.client_email")}
                                                   className='form-control' type="text"
                                                   value={updatedClient.emails || ""}
                                                   onChange={(e) => updateField("emails", e.target.value)} />
                                        </div>
                                        <div className='mb-2'>
                                            <h5 className='mt-4'>
                                                {data.t.read("client_table.configuration")}
                                            </h5>
                                            <div className='row mt-1'>
                                                <div className='col-2'>Type :</div>
                                                <div className='col-10'>
                                                    <select required className='form-control'
                                                            value={updatedClient.config?.type || ""}
                                                            onChange={(e) => updateField("config", e.target.value, "type")}>
                                                        <option value="">--</option>
                                                        <option value="legal">Legal</option>
                                                        <option value="resto">Restaurant</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row mt-1'>
                                                <div className='col-2'>{data.t.read("client_table.engine")}</div>
                                                <div className='col-10'>
                                                    <select required className='form-control'
                                                            value={updatedClient.config?.engine || ""}
                                                            onChange={(e) => updateField("config", e.target.value, "engine")}>
                                                        <option value="">--</option>
                                                        <option value="default">{data.t.read("client_table.default")}</option>
                                                        <option value="juris">Juris</option>
                                                        <option value="demo">Démo</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {updatedClient.config?.frontend_invoice && (
                                                <div className='row mt-1'>
                                                    <div className='col-2'>Frontend :</div>
                                                    <div className='col-10'>
                                                        <div className='row font-small'>
                                                            <div className='col-6'>
                                                                <label className='d-block'>
                                                                    <input type="checkbox"
                                                                           checked={updatedClient.config.frontend_invoice.hide_na_fields}
                                                                           onChange={() => updateField("config", !updatedClient.config.frontend_invoice.hide_na_fields, "frontend_invoice", "hide_na_fields")} /> {data.t.read("client_table.hide_empty_fields")}
                                                                </label>
                                                                <label className='d-block'>
                                                                    <input type="checkbox"
                                                                           checked={updatedClient.config.frontend_invoice.hide_cost_details_table}
                                                                           onChange={() => updateField("config", !updatedClient.config.frontend_invoice.hide_cost_details_table, "frontend_invoice", "hide_cost_details_table")} /> {data.t.read("client_table.hide_cost_details_table")}
                                                                </label>
                                                                <label className='d-block'>
                                                                    <input type="checkbox"
                                                                           checked={updatedClient.config.frontend_invoice.hide_work_date}
                                                                           onChange={() => updateField("config", !updatedClient.config.frontend_invoice.hide_work_date, "frontend_invoice", "hide_work_date")} /> {data.t.read("client_table.hide_work_date")}
                                                                </label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <label className='d-block'>
                                                                    <input type="checkbox"
                                                                           checked={updatedClient.config.frontend_invoice.hide_office_in_gl_details_table}
                                                                           onChange={() => updateField("config", !updatedClient.config.frontend_invoice.hide_office_in_gl_details_table, "frontend_invoice", "hide_office_in_gl_details_table")} /> {data.t.read("client_table.hide_office_from_gl_details")}
                                                                </label>
                                                                <label className='d-block'>
                                                                    <input type="checkbox"
                                                                           checked={updatedClient.config.frontend_invoice.hide_due_date}
                                                                           onChange={() => updateField("config", !updatedClient.config.frontend_invoice.hide_due_date, "frontend_invoice", "hide_due_date")} /> {data.t.read("client_table.hide_due_date")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='row mt-1'>
                                                <div className='col-2'>Modules :</div>
                                                <div className='col-10'>
                                                    <div className='row'>
                                                        {
                                                            components.map(component => {
                                                                return (
                                                                    <div key={component._id} className='col-2'>
                                                                        <label>
                                                                            <input type="checkbox"
                                                                                   checked={updatedClient.config.components.findIndex(comp => component._id === comp.id) !== -1}
                                                                                   onChange={() => toggleFrontendSection(component)} /> {component.name}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-1'>
                                                <div className='col-2'>{data.t.read("fiscal_year")} :</div>
                                                <div className='ml-3'>
                                                    <select value={updatedClient.config?.fiscal_year_start || ""}
                                                            onChange={(e) => updateField("config", e.target.value, "fiscal_year_start")}
                                                            className='form-control'>
                                                        <option value="" disabled>{data.t.read("start_fiscal_year")}</option>
                                                        <option value="01">{data.t.read("month.January")}</option>
                                                        <option value="02">{data.t.read("month.February")}</option>
                                                        <option value="03">{data.t.read("month.March")}</option>
                                                        <option value="04">{data.t.read("month.April")}</option>
                                                        <option value="05">{data.t.read("month.May")}</option>
                                                        <option value="06">{data.t.read("month.June")}</option>
                                                        <option value="07">{data.t.read("month.July")}</option>
                                                        <option value="08">{data.t.read("month.August")}</option>
                                                        <option value="09">{data.t.read("month.September")}</option>
                                                        <option value="10">{data.t.read("month.October")}</option>
                                                        <option value="11">{data.t.read("month.November")}</option>
                                                        <option value="12">{data.t.read("month.December")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row mt-1'>
                                                <div className='col-2'>
                                                    {data.t.read("default_cost_type")} :
                                                </div>
                                                <div className='ml-3'>
                                                    <input className='form-control' value={updatedClient.config?.cost_type || ""}
                                                           onChange={(e) => updateCostType(e.target.value)}
                                                           placeholder={data.t.read("default_cost_type")} />
                                                </div>
                                            </div>
                                            <div className='row mt-1'>
                                                <div className='col-2'>
                                                    {data.t.read("default_cost_type_nin")} :
                                                </div>
                                                <div className='ml-3'>
                                                    <input className='form-control' value={updatedClient.config?.cost_type_nin || ""}
                                                           onChange={(e) => updateCostTypeNIN(e.target.value)}
                                                           placeholder={data.t.read("default_cost_type_nin")} />
                                                </div>
                                            </div>
                                            <div className='row mt-1'>
                                                <div className='col-2'>
                                                    {data.t.read("Variance")} :
                                                </div>
                                                <div className='ml-3'>
                                                    <MoneyInput value={updatedClient.config?.variance || ""}
                                                                onChange={(value) => updateVariance(value)}
                                                                locale={`${data.t.lang}-${data.t.lang.toUpperCase()}`}
                                                                placeholder="variance" />
                                                </div>
                                            </div>
                                            {/* Invoice Exporter Settings */}
                                            <div className='row mt-2'>
                                                <div className='col-2'>{data.t.read("client_table.invoice_export")}</div>
                                                <div className='col-10'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {
                                                                ["export_supplier", "detail_on_multiple_line", "re_export_supplier"].map(option => {
                                                                    return (
                                                                        <div key={option} className='row mt-1'>
                                                                            <div className='col-5'>
                                                                                {data.t.read("client.csv_exporter." + option)}
                                                                            </div>
                                                                            <div className='col-4'>
                                                                                <input type="checkbox"
                                                                                       checked={getInvoiceExporterOption(option)}
                                                                                       onChange={() => toggleExporterOption(option)}
                                                                                       name={option} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <hr />
                                                            <div key={"separator_csv"} className='row mt-2'>
                                                                <div className='col-5'>
                                                                    {data.t.read("client.csv_exporter.separator")}
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="text"
                                                                           value={updatedClient.config?.invoice_exporter["separator"] || ""}
                                                                           onChange={(e) => changeExporterValues("separator", e)}
                                                                           name={"separator"} placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div key={"decimal_separator_csv"} className='row mt-2'>
                                                                <div className='col-5'>
                                                                    {data.t.read("client.csv_exporter.decimal_separator")}
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="text"
                                                                           value={updatedClient.config?.invoice_exporter["decimal_separator"] || ""}
                                                                           onChange={(e) => changeExporterValues("decimal_separator", e)}
                                                                           name={"decimal_separator"} placeholder="" />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div key={"unit_exporter"} className='row mt-1'>
                                                                <div className='col-5'>
                                                                    {data.t.read("client.csv_exporter.group_invoice_by")}
                                                                </div>
                                                                <div className='col-4'>
                                                                    <select
                                                                        value={updatedClient.config?.invoice_exporter.group_invoice_by || ""}
                                                                        onChange={(e) => changeExporterValues("group_invoice_by", e)}>
                                                                        {
                                                                            ["", "unit"].map(option => {
                                                                                return (
                                                                                    <option key={option}
                                                                                            value={option}>{option !== "" ? data.t.read("client.csv_exporter.group_options." + option) : ""}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div key={"details_column_amount"} className='row mt-2'>
                                                                <div className='col-5'>
                                                                    {data.t.read("client.csv_exporter.details_column_amount")}
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="number"
                                                                           value={updatedClient.config?.invoice_exporter["details_column_amount"] || 0}
                                                                           onChange={(e) => changeExporterValues("details_column_amount", e)}
                                                                           name={"details_column_amount"} min="0" />
                                                                </div>
                                                            </div>
                                                            <div key={"column_amount"} className='row mt-2'>
                                                                <div className='col-5'>
                                                                    {data.t.read("client.csv_exporter.column_amount")}
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="number"
                                                                           value={updatedClient.config?.invoice_exporter["column_amount"] || 0}
                                                                           onChange={changeColAmountExporter}
                                                                           name={"column_amount"} min="0" />
                                                                </div>
                                                            </div>
                                                            <div key={"taxes_gl"} className='row mt-2'>
                                                                <div className='col-5'>
                                                                    {data.t.read("client.csv_exporter.taxes_gl")}
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="text"
                                                                           value={updatedClient.config?.invoice_exporter["taxes_gl"] || ""}
                                                                           onChange={(e) => changeExporterValues("taxes_gl", e)}
                                                                           name={"taxes_gl"}
                                                                           placeholder="Example 2103,2104" />
                                                                </div>
                                                            </div>
                                                            <div className='row mt-1'>
                                                                {
                                                                    Array.from({ length: updatedClient.config?.invoice_exporter.column_amount || 0 }).map((_, n) => {
                                                                        return (
                                                                            <div className='col-4 mt-3' key={"column-" + n}>
                                                                                <label
                                                                                    htmlFor={"column-" + n}>{"Column #" + (n + 1)}</label><br />
                                                                                <select name={"column-" + n}
                                                                                        value={getColumnValueExporter(n)}
                                                                                        onChange={(e) => changeColumnValueExporter(e.target.value, n)}>
                                                                                    <option key={"no_data"}
                                                                                            value={""}></option>
                                                                                    <option key={"hard_coded"}
                                                                                            value={"HardCoded"}>HardCoded
                                                                                    </option>
                                                                                    <optgroup label="Invoice">
                                                                                        {
                                                                                            [
                                                                                                "supplier_name",
                                                                                                "supplier_number",
                                                                                                "location",
                                                                                                "invoice_number",
                                                                                                "invoice_date",
                                                                                                "invoice_due_date",
                                                                                                "invoice_total",
                                                                                                "comments"
                                                                                            ].map(option => {
                                                                                                return (
                                                                                                    <option key={option}
                                                                                                            value={option}>{option !== "" ? data.t.read("client.csv_exporter.options." + option) : ""}</option>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="Details">
                                                                                        {
                                                                                            [
                                                                                                "detail_gl_code",
                                                                                                "detail_description",
                                                                                                "detail_qty",
                                                                                                "detail_unit_price",
                                                                                                "detail_total",
                                                                                                "detail_work_date",
                                                                                                "detail_location"
                                                                                            ].map(option => {
                                                                                                return (
                                                                                                    <option key={option}
                                                                                                            value={option}>{option !== "" ? data.t.read("client.csv_exporter.options." + option) : ""}</option>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                </select>
                                                                                {
                                                                                    getColumnValueExporter(n) === "HardCoded" ?
                                                                                        <input className='mt-1' type="text"
                                                                                               value={getHardCodedValueExporter(n)}
                                                                                               onChange={(e) => changeColumnValueExporter("HardCoded:" + e.target.value, n)}
                                                                                               name={"HardCoded:" + n} />
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <h5 className='mt-4'>
                                                {data.t.read("client_table.unit_office_list")}
                                                <button className='btn btn-info btn-xs ml-2' type="button"
                                                        onClick={addUnit}>+
                                                </button>
                                            </h5>
                                            {
                                                updatedClient.supplier_units && updatedClient.supplier_units.map((unit, i) => {
                                                    return <div key={i}>
                                                        <div className='row mb-1'>
                                                            <div className='col-3'>
                                                                <input required
                                                                       placeholder={data.t.read("client_table.units_offices")}
                                                                       className='form-control' type="text"
                                                                       value={unit.unit}
                                                                       onChange={(e) => updateUnit(i, "unit", e.target.value)} />
                                                            </div>
                                                            <div className='col-7'>
                                                                <input required placeholder={"Description"}
                                                                       className='form-control' type="text"
                                                                       value={unit.desc}
                                                                       onChange={(e) => updateUnit(i, "desc", e.target.value)} />
                                                            </div>
                                                            <div className='col-2 text-right pt-1'>
                                                                <button type="button" className="btn btn-link"
                                                                        onClick={() => removeUnit(i)}>
                                                                    <FontAwesomeIcon className="text-danger"
                                                                                     icon={faWindowClose} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='row mt-1'>
                                            <div className='col-3'>Logo :</div>
                                            {updatedClient.config?.logo && (
                                                <div className='mr-2'>
                                                    <img src={updatedClient.config?.logo} alt="Selected"
                                                         style={{ width: '200px', height: '50px' }} />
                                                </div>
                                            )}
                                            <div>
                                                <label
                                                    className="btn btn-secondary btn-no-border-radius btn-primary-shadow pl-5 pr-5">
                                                    {data.t.read("browse")}
                                                    <input type="file" style={{ display: "none" }}
                                                           onChange={(evt) => updateLogo(evt.target.files)} />
                                                </label>
                                            </div>
                                        </div>
                                    </Modal>
                                </form>
                            )
                        }
                        {errorMessage != null && (
                            <Modal title="Attention" okText={"OK"} okAction={() => setErrorMessage(null)}>
                                <p style={{ whiteSpace: 'pre-line' }} className='text-danger'>{errorMessage}</p>
                            </Modal>
                        )}
                        {successMessage != null && (
                            <Modal title="Attention" okText={"OK"} okAction={() => setSuccessMessage(null)}>
                                {successMessage}
                            </Modal>
                        )}
                        {showImportSuppliers && (
                            <Modal key="import-modal" width="50vw" title={data.t.read("import_suppliers")} okText={data.t.read("import")} okAction={() => processImportSuppliers()} cancelText={"Annuler"} cancelAction={() => { setShowImportSuppliers(false); setImportFile(null); setShowSupplierCodesModal(true)}} >
                                <div>
                                    {data.t.read("client_table.csv_file_format")}
                                    <div className='card p-2'>
                                        {data.t.read("client_table.supplier_code_string")}<br />
                                        {data.t.read("client_table.supplier_code_string")}<br />
                                        {data.t.read("client_table.supplier_code_string")}<br />
                                    </div>
                                    <div className='mt-2'>
                                        <input className='form-control' type="file" id="import-csv-file" onChange={f => prepareImportSuppliers(f.target.files)} />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {showSupplierCodesModal && (
                            <Modal title={data.t.read("code_supplier_association")} okText={"OK"} titleText={data.t.read("import")} titleAction={() => {setShowImportSuppliers(true);setShowSupplierCodesModal(false)}}
                                   okAction={() => closeSupplierCodesModal()}>
                                <SupplierUnitsCodes type={"codes"} clientId={codeClientId} />
                            </Modal>
                        )}
                        {unitClientId != null && (
                            <Modal title={data.t.read("unit_office_association")} okText={"OK"} okAction={() => setUnitClientId(null)}>
                                <SupplierUnitsCodes type={"units"} clientId={unitClientId} />
                            </Modal>
                        )}
                    </div>
                    :
                    <div className="d-flex loading-container w-100">
                        <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
                    </div>
                }
            </div>
        </div>
    )
};

export default inject('data')(observer(AdminClient));
