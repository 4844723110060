import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faSync } from '@fortawesome/free-solid-svg-icons';

const Checkmark = ({ isChecked, onChecked, disabled, callback, circle }) => {
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(isChecked);

    useEffect(() => {
        setChecked(isChecked)
      }, [isChecked]);


    const handleCheckboxChange = async () => {
        if (disabled) return; // Prevent changes if disabled
        
        const newChecked = !isChecked;
        if (newChecked) {
            // If checked, execute onChange
            if (onChecked) {
                setLoading(true);
                const result = await onChecked();
                setChecked(result);
                setLoading(false);
                if(callback){
                    callback();
                } 
            }
        }
    }

    return <div className="mr-1 ml-1">
        { loading ?
             <FontAwesomeIcon className="text-secondary infinite-rotate faded" icon={faSync} />
            :
            <FontAwesomeIcon
            icon={circle ? faCheckCircle: faCheck}
            className={`pointer fa-2xl ${checked ? 'text-primary' : 'text-secondary'}`}
            onClick={handleCheckboxChange}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        />}
    </div>

};

export default Checkmark;
