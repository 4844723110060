import en from './en';
import fr from './fr';

export default class Translator {
    constructor(defaultLang = "fr") {
        this.locales = [];

		this.locales["en"] = en;
		this.locales["fr"] = fr;

        this.lang = defaultLang;

        if (window.localStorage["lang"] !== null) {
            this.changeLang(window.localStorage["lang"]);
        }
    }

    switchLang() {
        this.lang = this.lang === "en" ? "fr" : "en";
        this.changeLang(this.lang);
    }

    changeLang(lang) {
        this.lang = ["fr", "en"].includes(lang) ? lang : "fr";
        window.localStorage["lang"] = this.lang;
    }

    get(key) {
        return this.locales[this.lang][key];
    }

    getCurrentLang() {
        return this.lang;
    }

    read(key) {
        if (window.localStorage["lang"] === null || !["fr", "en"].includes(window.localStorage["lang"])) {
            this.changeLang(this.lang);
        }

        let arr = key.split(".");
        let obj = this.locales[this.lang];

        for(var i = 0; i < arr.length; i++){
            obj = obj[arr[i]];
        }

        return obj;
    }
}