import React, { useEffect, useState } from 'react';

const Checkbox = ({ isChecked, onChange , disabled}) => {
  const [checked, setChecked] = useState(isChecked);

  const handleCheckboxChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onChange) {
      onChange();
    }
  };

  useEffect(() =>
{ 
    setChecked(isChecked);
}
  , [isChecked]);

  return (
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onClick={handleCheckboxChange}
          className='pointer'
          readOnly />
  );
};

export default Checkbox;