import { useEffect, useState } from "react";
import axios from "axios";
import iconv from 'iconv-lite';
import closeIcon2 from '../assets/img/icon-close2.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faPlusSquare, faSpinner, faBars, faTimes, faSync, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import FormTextAreaAutoSaved from './form-text-area-auto-saved';
import FormTextField from './form-textfield';
import Modal from './modal';
import Checkbox from "../components/checkbox";
import CustomResizable from './custom-resizable';
import Utils from "../utils/utils";

import { fetch } from '../utils/api-wrapper';

const Expense = ({ data, account_id, selected, expenses, close, account_status }) => {
    
    const source = axios.CancelToken.source();
    const [pdfBuff, setPdfBuff] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [expense, setExpense] = useState(selected);
    const [currentView, setCurrentView] = useState("data");
    const [saving, setSaving] = useState(false);
    const [showDisapproval, setShowDisapproval] = useState(false);
    const [validating, setValidating] = useState(false);
    const [causeDenial, setCauseDenial] = useState(null);
    const [action, setAction] = useState();
    const [savingError, setSavingError] = useState(null);
    const [showConfirmRestartProcess, setShowConfirmRestartProcess] = useState("false");
    const [confirmDeleteViewId, setConfirmDeleteViewId] = useState(null);
    const [billablesSelected, setBillablesSelected] = useState([]);
    const [nonBillablesSelected, setNonBillablesSelected] = useState([]);
    const [confirmDeleteItems, setConfirmDeleteItems] = useState(null);

    useEffect(() => () => source.cancel("Expense unmounted"), []);

    useEffect(() => {
        if (saving === true)
            updateExpense()
    }
        , [saving])

    useEffect(() => {

        if (data.isMobile)
            return;

        fetch(`/invoice/pdf/${account_id}_${expense.checksum}`, "GET", {},
            response => {
                let buf = null;
                if (response.data.pdf != null) {
                    buf = iconv.encode(response.data.pdf, 'iso-8859-1');
                    let bb = new Blob([buf], { type: "application/pdf" });
                    buf = window.URL.createObjectURL(bb);
                    setPdfBuff(buf);
                }
            },
            null, false, source.token);

    }, [expense.checksum])

    const showStatus = (value) => {
        return (value != null && value !== "") || data.config.frontend_invoice == null || !data.config.frontend_invoice.hide_na_fields ? "flex" : "none";
    }

    const updateExpense = (action = 'update') => {
        const formData = new FormData();
        setAction(action);
        formData.append("json", JSON.stringify(expense.json));
        formData.append("status_details", expense.status_details);
        formData.append("status", expense.status);
        fetch("/expenses/" + account_id + "/" + expense.checksum, "PUT", formData,
            response => {
                setSaving(false);
                setExpense(response.data.expense);
            },
            error => {
                setSaving(false);
                console.error(error);
                setSavingError(data.t.read("internal_saving_error"))
            })
    }

    const validateExpense = () => {
        setValidating(true);
        const formData = new FormData();
        formData.append("expense", JSON.stringify(expense));
        fetch(`/expenses/validate/${account_id}/${expense.checksum}`, "PUT", formData,
            response => {
                setValidating(false);
                setExpense(response.data.expense);
                getNextExpense(true, true);
            },
            error => {
                setValidating(false);
                console.error(error);
                setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);

            })
    }

    const restartProcess = () => {
        fetch(`/expenses/restart-processing/${account_id}/${expense.checksum}`, "GET", {},
            response => {
                close();
            },
            null, false, source.token);
    }

    const isItemBillable = (item) => {
        return item.IsBillable;
    }

    const hasBillableItems = () => {
        return expense.json && expense.json.ListItem && expense.json.ListItem.filter(item => isItemBillable(item)).length > 0;
    }

    const addItem = (billable = false) => {
        let listItem = expense.json.ListItem;
        if (listItem === undefined || listItem === null)
            listItem = []
        listItem.push({
            "_view_id": listItem.length,
            "IsBillable": billable ? 1 : 0,
            "MatterId": "",
            "Total": 0
        });

        setExpense(prev => ({ ...prev, json: { ...prev.json, ListItem: listItem } }));
        setSaving(true);
    }

    const toggleItemSelected = (section, item) => {
        let list = section === "billable" ? billablesSelected : nonBillablesSelected;
        let idx = list.findIndex(it => it._view_id === item._view_id);

        if (idx !== -1) {
            list.splice(idx, 1)
        }
        else {
            list.push(item);
        }

        section === "billable" ? setBillablesSelected(list) : setNonBillablesSelected(list);

    }

    const deleteItem = (viewId) => {
        const listItem = expense.json.ListItem.filter(item => item._view_id !== viewId);
        setExpense(prev => ({ ...prev, json: { ...prev.json, ListItem: listItem } }));
        setSaving(true);
        setConfirmDeleteViewId(null);
    }

    const confirmDeleteAction = (section) => {
        let list = section === "billable" ? billablesSelected : nonBillablesSelected;
        setConfirmDeleteItems(list);
        section === "billable" ? setBillablesSelected([]) : setNonBillablesSelected([]);
    }

    const deleteItems = () => {
        const listItem = expense.json.ListItem.filter(item => !confirmDeleteItems.includes(item));
        setExpense(prev => ({ ...prev, json: { ...prev.json, ListItem: listItem } }));
        setSaving(true);
        setConfirmDeleteItems(null);
    }

    const updateItem = (item) => {
        const listItem = expense.json.ListItem;
        const index = listItem.findIndex(it => it._view_id === item._view_id);
        listItem[index] = item;
        setExpense(prev => ({ ...prev, json: { ...prev.json, ListItem: listItem } }));
        setSaving(true);
    }

    const toggleSelectAll = (sectionName) => {
        if (sectionName === "non-billable")
            setNonBillablesSelected(expense.json.ListItem && expense.json.ListItem.filter(item => !isItemBillable(item)).length !== nonBillablesSelected.length ? expense.json.ListItem.filter(item => !isItemBillable(item)) : [])
        else
            setBillablesSelected(expense.json.ListItem && expense.json.ListItem.filter(item => isItemBillable(item)).length !== billablesSelected.length ? expense.json.ListItem.filter(item => isItemBillable(item)) : [])
    }


    /**
     * when getting the next expense we call ask to remove it from list 
     * @param {boolean} remove 
     * @param {boolean} forward 
     */
    const getNextExpense = (remove = true, forward = true) => {
        let nextIndex;
        const indexCurrentInvoice = expenses.findIndex(
            xpen => xpen.checksum === expense.checksum
        );

        if (forward === true) {
            if (indexCurrentInvoice + 1 < expenses.length) {
                nextIndex = indexCurrentInvoice + 1
            }
            else {
                nextIndex = 0
            }
        }

        else if (forward === false) {
            if (indexCurrentInvoice - 1 >= 0)
                nextIndex = indexCurrentInvoice - 1;
            else
                nextIndex = expenses.length - 1;
        }

        if (remove === true) {
            expenses.splice(indexCurrentInvoice, 1);
            nextIndex = indexCurrentInvoice;
        }

        if (expenses.length > nextIndex) {
            setExpense(expenses[nextIndex]);
        }
        else
            close();
    }


    const updateExpenseStatus = (e) => {
        if (e.target.value === "Validated") {
            validateExpense();
            return;
        }
        if (e.target.value === "Approved") {
            approveExpense();
            return;
        }
        if (e.target.value === "Denied") {
            setShowDisapproval(true);
            return;
        }
        setExpense(prev => ({ ...prev, status: e.target.value }));
        setSaving(true);
    };

    const approveExpense = () => {
        setExpense(prev => ({ ...prev, status: 'Approved' }));
        setAction('approving');
        setSaving(true); 
    }
   
    const disApproveExpense = () => {
        setAction('disApproving');
        setExpense(prev => ({ 
            ...prev,
            status: 'Denied',
            status_details: new Date().toLocaleString() +' : ' + data.firstName +' '+ data.lastName + ' : '+ causeDenial + '\n'+ expense?.status_details
            }
        ));
        setShowDisapproval(false);
        setSaving(true);
    }

    return expense == null ?
        <div className="loading-container">
            <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
        </div>
        :
        <div className="invoice bg-white">
            {
                pdfBuff !== null &&
                <CustomResizable type="Invoice">
                    <div className="image-section position-relative">
                        <div className="d-flex main-tabs">
                            <div>
                                <div className="tab-title p-3 pointer " onClick={() => setMenuVisible(true)}>
                                    <FontAwesomeIcon icon={faBars} style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                            {
                                menuVisible ?
                                    <div className="left-menu-section">
                                        <div>
                                            <div className="d-flex main-tabs mb-4">
                                                <div className="tab-title text-secondary p-3 pointer" onClick={() => setMenuVisible(false)}>
                                                    <FontAwesomeIcon className="text-secondary" icon={faTimes} style={{ fontSize: "25px" }} />
                                                </div>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <div className="mock-link text-danger" >
                                                            {data.t.read("delete")}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <object className="pdf" width="800" height="500" type="application/pdf" data={pdfBuff} aria-label="PDF"></object>
                    </div>
                </CustomResizable>
            }
            {
                <div className={data.isMobile ? "content-section d-flex flex-column mb-5": "content-section d-flex flex-column"}>
                    <div className="d-flex main-tabs">
                        {
                            expenses && expenses.length > 1 &&
                            <div className='pointer icon-box'>
                                <FontAwesomeIcon disabled={saving} icon={faAngleLeft} className="fa-2x pointer" color='#F39800' onClick={() => getNextExpense(false, false)} />
                            </div>
                        }
                        <div className={"flex-grow-1 p-3 tab-title " + (currentView === "data" ? "tab-selected" : "")} onClick={() => setCurrentView("data")}>
                            {data.t.read("data")}
                        </div>
                        <div className="p-3 pointer" onClick={() => close()}>
                            <img src={closeIcon2} alt="Close" />
                        </div>
                        {
                            expenses && expenses.length > 1 &&
                            <div className='pointer icon-box'>
                                <FontAwesomeIcon disabled={saving} icon={faAngleRight} className="fa-2x pointer" color='#F39800' onClick={() => getNextExpense(false, true)} />
                            </div>
                        }
                    </div>
                    <div className="p-4 d-flex flex-column flex-grow-1">
                        <div className={ data.isMobile ? "row mb-2" : "row"}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("invoice.status_txt")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <select className="form-control form-control-sm input-group-sm" disabled={saving} value={expense.status} onChange={updateExpenseStatus}>
                                    {
                                        data.config.invoice_status.map(tmp => {
                                            return <option key={tmp} value={tmp}>{data.t.read("status." + tmp.toLowerCase())}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.InvoiceDate) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("invoice.billing_date")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="date" locked={saving} value={expense.json?.InvoiceDate ? expense.json?.InvoiceDate : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, InvoiceDate: val } })); setSaving(true) }} />
                            </div>
                        </div>
                        <div className="row" style={{ display: showStatus(expense.json?.InvoiceNo) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("invoice.invoice_number")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="data" locked={saving} value={expense.json?.InvoiceNo} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, InvoiceNo: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Currency) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("invoice.currency")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="data" locked={saving} value={expense.json?.Currency} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Currency: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Category) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("category")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="data" locked={saving} value={expense.json?.Category} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Category: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Total) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("invoice.total")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="money" locked={saving} value={expense.json?.Total ? expense.json?.Total : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Total: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Tax) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("invoice.tax")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="money" locked={true} value={expense.json?.Tax ? expense.json?.Tax : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Tax: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.TotalWithTax) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("invoice.total_with_tax")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="money" locked={true} value={expense.json?.TotalWithTax ? expense.json?.TotalWithTax : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, TotalWithTax: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.Tip) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                {data.t.read("tips")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="money" locked={saving} value={expense.json?.Tip ? expense.json?.Tip : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Tip: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <div className="row" style={{ display: showStatus(expense.json?.BigTotalPayed) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold mt-1" : "col-3 font-weight-bold"}>
                                Xpense {data.t.read("invoice.total")} :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value": "col-3 invoice-value"}>
                                <FormTextField format="money" locked={true} value={expense.json?.BigTotalPayed ? expense.json?.BigTotalPayed : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, BigTotalPayed: val } })); setSaving(true) }} />
                            </div>
                        </div>
                        <div className="mt-5">
                            <h4 className="hidden-actions-container d-flex justify-content-between">
                                <span>
                                    {data.t.read("invoice.cost_details")}
                                    <span className="hidden-actions">
                                        <button className="btn btn-sm btn-link" onClick={() => addItem(true)}>
                                            <FontAwesomeIcon className="text-success" icon={faPlusSquare} />
                                        </button>
                                    </span>
                                </span>
                                <div className="text-right">
                                    <button className={"btn btn-secondary btn-sm btn-xs " + (billablesSelected.length > 0 ? "" : "faded")} onClick={() => confirmDeleteAction("billable")} >{data.t.read("delete")}</button>
                                </div>
                            </h4>
                            {hasBillableItems() &&
                                <table className={data.isMobile ? "table invoice-items-table mt-3 font-xsmall":"table invoice-items-table mt-3 font-xsmall col-6"}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <Checkbox isChecked={expense.json.ListItem.filter(item => isItemBillable(item)).length === billablesSelected.length && billablesSelected.length > 0} onChange={e => toggleSelectAll("billable")} />
                                            </th>
                                            <th>{data.t.read("invoice.matter_id")}</th>
                                            <th>{data.t.read("invoice.amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            expense.json.ListItem.filter(item => isItemBillable(item)).map((item) => {
                                                return <tr key={"invoice-item-" + item._view_id}>
                                                    <td onClick={e => e.stopPropagation()}>
                                                        <Checkbox isChecked={billablesSelected.findIndex(it => it._view_id === item._view_id) > -1} onChange={e => toggleItemSelected("billable", item)} />
                                                    </td>

                                                    <td>
                                                        <FormTextField format="data" locked={saving} value={item.MatterId} validateChange={val => updateItem({ ...item, MatterId: val })} />
                                                    </td>
                                                    <td>
                                                        <div className="position-relative">
                                                            <FormTextField format="money" locked={saving} value={item.Total} validateChange={val => updateItem({ ...item, Total: val })} />
                                                            <div className="item-actions">
                                                                <button className="btn btn-sm btn-link" onClick={() => setConfirmDeleteViewId(item._view_id)}>
                                                                    <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>}
                        </div>

                        <h4 className="mt-5" style={{ display: showStatus(expense.json?.Tax1) === "flex" || showStatus(expense.json?.Tax1) === "flex" ? "block" : "none" }}>{data.t.read("invoice.tax_details")}</h4>

                        <div className="row" style={{ display: showStatus(expense.json?.Tax1) }}>
                            <div className={data.isMobile ? "col-6 font-weight-bold":"col-2 font-weight-bold"}>
                                {data.t.read("invoice.tax")} 1 :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={saving} value={expense.json?.Tax1 ? expense.json.Tax1 : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Tax1: val } })); setSaving(true) }} />
                            </div>
                        </div>
                        <div className="row" style={{ display: showStatus(expense.json?.Tax2) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold":"col-2 font-weight-bold"}>
                                {data.t.read("invoice.tax")} 2 :
                            </div>
                            <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                                <FormTextField format="money" locked={validating | saving} value={expense.json?.Tax2 ? expense.json.Tax2 : ""} validateChange={val => { setExpense(prev => ({ ...prev, json: { ...prev.json, Tax2: val } })); setSaving(true) }} />
                            </div>
                        </div>

                        <h4 className="mt-5">{data.t.read("comments")}</h4>
                        <div>
                            <FormTextAreaAutoSaved locked={validating | saving} value={expense.status_details} validateChange={val => { setExpense(prev => ({ ...prev, status_details: val })); setSaving(true) }} />
                        </div>

                        <div id="errorMessages" className="errors"></div>
                        {
                            <div className="mt-5 d-flex justify-content-between">
                                <div>
                                    <button className="btn btn-sm btn-secondary mr-2" onClick={() => setShowConfirmRestartProcess(true)}>
                                        {data.t.read("invoice.restart_processing")}
                                    </button>
                                </div>
                                { account_status === 'Submitted' &&
                                    <button className="btn btn-primary" disabled={validating | saving} onClick={() => validateExpense()}>
                                    {data.t.read("validate")}
                                    {validating && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                </button>
                                }
                                
                                { Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data,['Admin', 'Approver']) && <div>
                                    <button className="btn mr-1  btn-primary" hidden={expense.status !== "Need_Approval"} 
                                        onClick={() => approveExpense()}>
                                        {data.t.read("approve")}
                                        {saving && action === 'approving' && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                    </button>
                                    
                                    <button className="btn btn-danger " hidden={expense.status !== "Need_Approval"}  onClick={() => setShowDisapproval(true)}>
                                        {data.t.read("disapprove")}
                                    </button>
                                </div>
                                }
                                
                            </div>
                        }
                    </div>
                </div>
            }
            {
                showConfirmRestartProcess === true &&
                    <Modal title={data.t.read("confirm")} cancelAction={() => setShowConfirmRestartProcess(false)} okAction={() => restartProcess()} okText={data.t.read("yes")} cancelText={data.t.read("cancel")}>
                        {data.t.read("invoice.restart_processing")} ?
                    </Modal>
                    
            }
            {
                confirmDeleteItems != null &&
                    <Modal title={data.t.read("confirm")} cancelAction={() => setConfirmDeleteItems()} okAction={() => deleteItems()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                        {data.t.read("delete")} ?
                    </Modal>
                    
            }
            {
                confirmDeleteViewId != null &&
                    <Modal title={data.t.read("confirm")} cancelAction={() => setConfirmDeleteViewId(null)} okAction={() => deleteItem(confirmDeleteViewId)} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                        {data.t.read("delete")} ?
                    </Modal>
                   
            }
            {
                savingError != null &&
                    <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                        <div className="text-danger">
                            {savingError}
                        </div>
                    </Modal>
                   
            }
            {
            showDisapproval &&
                <Modal overflow="visible" title={data.t.read("denial_cause_placeholder")} cancelAction={() => setShowDisapproval(false)} okAction={() => disApproveExpense()} okText={data.t.read("submit")} cancelText={data.t.read("cancel")}>         
                        <input className='form-control' type="text" onChange={e => setCauseDenial(e.target.value) }  />
                </Modal>
            }
        </div>
}

export default Expense;