import { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import MoneyInput from "./money-input";
import Modal from '../components/modal';
import Switch from "react-switch";
import Select from 'react-select';

import { fetch } from '../utils/api-wrapper';

const GlNatural = ({ t, glNatural, close, config }) => {
    

    const source = axios.CancelToken.source();
    const [gl, setGl] = useState(glNatural);
    const [saving, setSaving] = useState(false);
    const [savingError, setSavingError] = useState(null);

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEsc);
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [close]);

    const save = () => {
        let formData = new FormData();

        if(gl._id !== undefined) 
            gl._id = gl._id.$oid

        formData.append("gl", JSON.stringify(gl));

        setSaving(true);
        fetch(`/gl_natural/`, "POST", formData, (response) => {
            setSaving(false);
            close();
        },
            (error) => {
                setSaving(false);
                setSavingError(t.read("error"))
            }
            , false, source.token)
    }

    return savingError != null ?
        <Modal title={t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
            <div className="text-danger">
                {savingError}
            </div>
        </Modal>
        :
        <div className="card">
            <div className="card-header text-center">
                GL Code
            </div>
            <div className="card-body">

                <div className="d-flex">
                    <label className="col-6 mt-2">
                        {t.read("gl_natural")}
                    </label>

                    <input className="form-control" type="text" value={gl.GLNatural} disabled={gl._id}
                        onChange={e => setGl(prev => ({ ...prev, GLNatural: e.target.value }))}
                    />
                </div>
                <div className="d-flex">
                    <label className="col-6 mt-2">
                        {t.read("budget")}
                    </label>
                    <MoneyInput value={gl.budget} onChange={val => setGl(prev => ({ ...prev, budget: val }))} locale={`${t.lang}-${t.lang.toUpperCase()}`} placeholder="budget" />
                </div>
                <div className="d-flex">
                    <label className="col-6 mt-2">
                        Label
                    </label>

                    <input className="form-control" value={gl.label}
                        onChange={e => setGl(prev => ({ ...prev, label: e.target.value }))}
                    />
                </div>
                <div className="d-flex">
                    <label className="col-6 mt-2">
                        {t.read("category")}
                    </label>

                    <Select
                        value={gl.category}
                        className="col-6 p-0"
                        name="categorie"
                        options={config.gl_categories && config.gl_categories.map(c => ({ value: c, label: t.read(`gl_category.${c}`) }))}
                        styles={styleSelect}
                        classNamePrefix="select"
                        onChange={(choice) => setGl(prev => ({ ...prev, category: {value: choice.value, label: choice.label} }))}
                    />

                </div>

                <div className='d-flex'>
                    <div className='col-6 mt-2'>{t.read("client.users.active")}</div>
                    <Switch className='switch'
                        checked={gl.active}
                        width={70}
                        onColor="#F39800"
                        onHandleColor="#2693e6"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        onChange={(active) => setGl(prev => ({ ...prev, active: active }))}
                    />
                </div>

                <div className='d-flex justify-content-center mt-2'>
                    <div>
                        <button onClick={() => save()} disabled={saving} className="btn btn-primary">{t.read("save")}
                            {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                        </button>
                    </div>
                </div>

            </div>

        </div>
}

export default GlNatural;