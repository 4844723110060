import React, { Component } from 'react';
import { inject } from "mobx-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(class FormSelect extends Component  {
    constructor(props) {
        super(props);

        this.state = {
            isOpen : false,
            chosenOption : this.props.chosenOption === "" ? null : this.props.chosenOption,
        }
    }

    updateOption(option) {
        this.setState({
            isOpen : false,
            chosenOption : option
        });

        this.props.optionChanged(option)
    }

    render() {
        return  <div className="position-relative">
                    <div className="d-flex form-select-box" onClick={() => this.setState({isOpen : !this.state.isOpen})}>
                        <div>{this.state.chosenOption == null ? this.props.title : this.state.chosenOption}</div>
                        <div>
                            <FontAwesomeIcon className="text-primary" icon={faCaretDown} />
                        </div>
                    </div>
                    {
                        this.state.isOpen && this.props.options != null && this.props.options.length > 0 ?
                            <div className="position-absolute form-select-option">
                                {
                                    this.props.title != null ?
                                        <div onClick={() => this.updateOption(null)}>
                                            {this.props.title}
                                        </div>
                                    :
                                        null
                                }
                                {
                                    this.props.options.map(op => {
                                        return (
                                                <div key={op} onClick={() => this.updateOption(op)}>
                                                {op}
                                               </div>
                                        )
                                    })
                                }
                            </div>
                        :
                            null
                    }
                </div>
    }
});