import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { observable, configure } from "mobx";
import { BrowserRouter } from "react-router-dom";
import Translator from './locales/translator';
import { useMediaQuery } from 'react-responsive';
import App from './App';
require(`./scss/${process.env.REACT_APP_ENV.replace(/\\.*$/, '')}.scss`);

configure({
    enforceActions: "never",
});

let lang = navigator.language || navigator.userLanguage;
if (lang) {
    lang = lang.substring(0, lang.indexOf("-"));
}

const savedData = {
    username: "",
    hideMenu: false,
    isMobile: false,
    isMenuOpen: false,
    t: new Translator(lang),
    selectedStatus: "All"
};

const data = observable(savedData);

const AppWrapper = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    useEffect(() => {
        data.isMobile = isMobile;
    }, [isMobile]);

    return (
        <BrowserRouter>
            <App data={data} />
        </BrowserRouter>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <AppWrapper />
    </React.StrictMode>,
    document.getElementById('root')
);
