import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const isAuthenticated = () => {
  return !!window.localStorage.getItem('jwt');
};

const ProtectedRoute = () => {
  return !isAuthenticated() ? <Navigate to="/signin" replace /> : <Outlet/>;
};

export default ProtectedRoute;