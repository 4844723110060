import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PlProcessingAccount = () => {
  const history = useNavigate();
  const [clientToken, setClientToken] = useState(null);
  

  // Load the JavaScript file dynamically only once when the component mounts
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://payload.co/Payload.js';
    script.async = true;

    document.body.appendChild(script);

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Function to generate the client token
  const generateClientToken = () => {
    fetch(`/payments/authenticate/pl/`,
      'GET',
      null,
      (response) => {
        setClientToken(response.data.pl_client_token);
      },
      (error) => {
        // Handle error
      }
    );
  };

  const goBack = () => {
    history(-1); // This function navigates back to the previous page or route
  };

  // Generate client token when the component mounts
  useEffect(() => {
    generateClientToken();

    // Clean-up function to cancel request if component unmounts
    return () => {
      // Cancel the request
    };
  }, []);

  // Handle Payload.js functionality when clientToken changes
  useEffect(() => {
    if (clientToken) {
      window.Payload(clientToken); // Use window to access global variable

      new window.Payload.ProcessingAccount()
        .on('success', function (evt) {
          console.log(evt.account.id);
          goBack();
        })
        .on('closed', goBack);
    }
  }, [clientToken]);

  return <></>; 
};

export default PlProcessingAccount;
