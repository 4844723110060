import { saveAs } from 'file-saver';
import iconv from 'iconv-lite';
import JSZip from 'jszip';

import { fetch } from './api-wrapper';

/**
 * This function downloads an invoice in the given format.
 * @param {string} id - Invoice ID
 * @param {string} format - Format (e.g., json, pdf, acomba)
 * @param {function} [doneCallBack=null] - Optional callback after the download is complete
 */
export const downloadFile = (id, format, doneCallBack = null) => {
    const { ext, mimeType } = formatToExtensionMime(format);

    fetch(`/invoices/${id}/${format}`, "GET", {},
        (response) => {
            if (format === "acomba") {
                response.data.forEach(file => {
                    if (file.file_string === "") return;

                    const a = document.createElement('a');
                    a.href = 'data:attachment/csv,' + encodeURIComponent(file.file_string);
                    a.target = '_blank';
                    a.download = file.file_name + '.csv';

                    document.body.appendChild(a);
                    a.click();
                });

                if (doneCallBack) doneCallBack();
                return;
            }

            let filename = "invoice" + ext;
            const pom = document.createElement('a');

            if (format === "json") {
                response.data = JSON.stringify(response.data);
            } else if (format === "pdf") {
                response.data = iconv.encode(response.data.pdf, 'iso-8859-1');
            }

            const bb = new Blob([response.data], { type: mimeType });

            pom.setAttribute('href', window.URL.createObjectURL(bb));
            pom.setAttribute('download', filename);

            pom.dataset.downloadurl = [mimeType, pom.download, pom.href].join(':');
            pom.draggable = true;
            pom.classList.add('dragout');

            pom.click();
            if (doneCallBack) doneCallBack();
        },
        (error) => {
            if (doneCallBack) doneCallBack();
        }
    );
};

/**
 * This function takes a format (e.g., json, elite_xml, pdf) and returns the extension + mimetype of that format.
 * @param {string} format - Format (e.g., json, elite_xml, pdf)
 * @returns {Object} { ext, mimeType }
 */
const formatToExtensionMime = (format) => {
    let ext = "";
    let mimeType = "";

    switch (format) {
        case "elite_xml": ext = ".xml"; mimeType = "text/xml"; break;
        case "pdf": ext = ".pdf"; mimeType = "application/pdf"; break;
        case "acomba": ext = ".csv"; mimeType = "text/csv"; break;
        default: ext = ".json"; mimeType = "application/json"; break;
    }

    return { ext, mimeType };
};

/**
 * This function takes a list of invoice ids and the download format to download all the invoices into a zip file.
 * @param {array} ids - List of invoice IDs
 * @param {string} format - Format (e.g., json, pdf, acomba)
 * @param {function} [doneCallBack=null] - Optional callback after the download is complete
 */
export const downloadFiles = async (ids, format, doneCallBack = null) => {
    const { ext, mimeType } = formatToExtensionMime(format);

    const zip = new JSZip();
    await fetch(`/invoices/${format}/`, "GET", { ids },
        (response) => {
            if (format === "acomba") {
                response.data.forEach(file => {
                    if (file.file_string === "") return;

                    const a = document.createElement('a');
                    a.href = 'data:attachment/csv,' + encodeURIComponent(file.file_string);
                    a.target = '_blank';
                    a.download = file.file_name + '.csv';

                    document.body.appendChild(a);
                    a.click();
                });
                return;
            }

            response.data.invoices.forEach((el) => {
                let tmp = el.invoice;
                const id = el.id;

                if (format === "json") {
                    tmp = JSON.stringify(el.data);
                } else if (format === "pdf") {
                    tmp = iconv.encode(el.pdf, 'iso-8859-1');
                }

                const bb = new Blob([tmp], { type: mimeType });
                zip.file(`invoice-${id}${ext}`, bb);
            });

            zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, "invoices.zip");
                if (doneCallBack) doneCallBack();
            });
        },
        (error) => {
            if (doneCallBack) doneCallBack();
        }
    );
};
