import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faCheckSquare, faSync } from '@fortawesome/free-solid-svg-icons';
import MoneyInput from './money-input';

import {fetch} from '../utils/api-wrapper';

export default inject("data")(observer(class FormTextfield extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            selected: false,
            startSaving: false,
            value: this.props.value === null ? "" : this.props.value,
            invalidValue: false,
            wasModified: false,
            searchPropositions: null,
            typedValue: "",
            showSearchResults: true, // Controls visibility of search results
        }

        if(props.passClearRecommendationFunc)
            props.passClearRecommendationFunc(() => this.setState({searchPropositions: null}));

        this.cachedSearchResults = {};
        this.searchTimeoutId = null;
        this.searchResultsRef = React.createRef();
    }

    formatMoney(number) {
        let result = this.props.data.t.read("n_a");

        if (number != null && number !== "") {
            if (isNaN(number) || this.state.invalidValue) {
                result = number;
            }
            else {
                result = "$ " + parseFloat(number).toFixed(2);
            }
        }

        return result;
    }

    formatData(data) {
        if (data === null || data === undefined || data.length === 0) {
            if (this.props.hideNA === null || !this.props.hideNA) {
                data = this.props.data.t.read("n_a");
            }
        }

        return data;
    }

    formatDate(data) {
        if (data == null || data.length === 0) {
            if (this.props.hideNA == null || !this.props.hideNA) {
                data = this.props.data.t.read("n_a");
            }
        }

        return data;
    }

    getValue() {
        let val = this.state.value;

        if (!this.state.selected) {
            if (this.props.format === "data") {
                val = this.formatData(val);
            }
            else if (this.props.format === "money") {
                val = this.formatMoney(val);
            }
            else if (this.props.format === "date") {
                val = this.formatDate(val);
            }
        }

        if(val === undefined)
            return "";

        return val;
    }

    close() {
        this.setState({
            value: this.props.value == null ? "" : this.props.value,
            selected: false,
            wasModified: false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.startSaving && !this.props.locked && prevProps.locked) {
            this.setState({
                startSaving: false,
                wasModified: false,
            })
        }
        else if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    valueChanged(value) {
        this.setState({
            value: value,
            wasModified: true,
        }, () => {
            if (this.props.format === "money") {
                if (this.state.value !== "" && isNaN(this.state.value)) {
                    if (!this.state.invalidValue) {
                        this.setState({
                            invalidValue: true,
                        })
                    }
                }
                else if (this.state.invalidValue) {
                    this.setState({
                        invalidValue: false,
                    })
                }
            }
        })

    }

    save() {
        if (!this.state.invalidValue) {
            this.setState({
                startSaving: true
            }, () => {
                    this.props.validateChange(this.state.value)
            })
        }
    }

    componentWillUnmount() {
        if (this.searchTimeoutId != null) {
            clearTimeout(this.searchTimeoutId);
            this.searchTimeoutId = null;
        }

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.searchResultsRef.current && !this.searchResultsRef.current.contains(event.target)) {
            this.setState({ showSearchResults: false });
        }
    };


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    keyTyped(val) {
        val = val.trim().replace(/\//g, '');

        if (this.props.searchURL != null) {
            if (this.searchTimeoutId != null) {
                clearTimeout(this.searchTimeoutId);
                this.searchTimeoutId = null;
            }

            if (val.length > 0) {
                if (this.cachedSearchResults[val] == null) {
                    this.searchTimeoutId = setTimeout(() => {
                        fetch(this.props.searchURL + encodeURIComponent(val), "GET", {},
                            response => {
                                this.cachedSearchResults[val] = response.data;

                                this.setState({
                                    typedValue: val,
                                    searchPropositions: this.cachedSearchResults[val]
                                });
                            });
                    }, 333);
                }
                else {
                    this.setState({
                        typedValue: val,
                        searchPropositions: this.cachedSearchResults[val]
                    });
                }
            }
            else {
                this.setState({
                    typedValue: val,
                    searchPropositions: null
                })
            }
        }
    }

    selectSearchProposition(e, proposition) {
        this.setState({
            searchPropositions: null,
            value: proposition.name,
            selected: false,
            startSaving: true
        }, () => {
            if (this.props.searchCallback == null) {
                this.save();
            }
            else {
                this.props.searchCallback(proposition);
            }
        })
    }

    addSearchProposition() {
        this.cachedSearchResults = {};
        this.props.searchCallback(null, this.state.typedValue);
    }

    reset(){

    }

    render() {
        return <div className="form-field d-flex">
            {
                this.props.format === "textarea" ?
                    <textarea disabled={this.props.locked} onFocus={() => this.setState({selected: true})}
                              onChange={e => this.valueChanged(e.target.value)}
                              onBlur= {() => this.setState({selected: false})}
                              className={`form-control form-control-sm ${this.state.selected ? "field-selected" : "field-unselected"} ${this.state.invalidValue ? "text-danger" : ""}`}
                              style={{maxWidth: "100%"}} value={this.getValue()}/>
                    :
                    this.props.format === "money" ?
                    <MoneyInput value={(this.state.value && parseFloat(this.state.value).toFixed(2)) || null}
                     onChange={e => this.props.validateChange(e)} locale={`${this.props.data.t.lang}-${this.props.data.t.lang.toUpperCase()}`} 
                     placeholder={this.props.data.t.read('n_a')} 
                      />
                    :
                    <input
                        onKeyUp={e => this.keyTyped(e.target.value)}
                        type={this.props.format === "date" && this.state.selected ? "date" : "text"}
                        max={this.props.format === "date" ? "9999-12-31" : null}
                        disabled={this.props.locked}
                        onBlur= {() => this.setState({selected: false})}
                        onFocus={() => this.setState({selected: true, showSearchResults: true})}
                        onChange={e => this.valueChanged(e.target.value)}
                        className={`form-control form-control-sm ${this.state.selected ? "field-selected" : "field-unselected"} ${this.state.invalidValue ? "text-danger" : ""}`}
                        style={{maxWidth: "100%"}}
                        value={this.getValue() ? this.getValue().toString() : ""}
                    />
            }
            {
                (this.state.showSearchResults && this.state.searchPropositions != null &&
                    (this.state.searchPropositions.length !== 0 || this.props.hideNoResults == null || !this.props.hideNoResults)) ?
                    <div className="search-results" ref={this.searchResultsRef}>
                        {
                            (this.state.searchPropositions && Array.isArray(this.state.searchPropositions) && this.state.searchPropositions.length > 0) ?
                                <React.Fragment>
                                    {
                                        this.state.searchPropositions.map(proposition => {
                                            return <div className='mb-2 pointer' key={proposition._id}
                                                        onClick={e => this.selectSearchProposition(e, proposition)}>
                                                <span>{proposition.name}</span>
                                                {proposition.description &&
                                                    <span className="font-italic font-xxsmall"> {proposition.description} </span>}
                                            </div>
                                        })
                                    }
                                    <div className='font-italic semi-faded mt-1'>
                                        {
                                            this.props.hideAddOperation == null || !this.props.hideAddOperation ?
                                                <div>
                                                    {this.props.data.t.read("if_result_not_present")}
                                                    <span className='text-primary' role="button" onClick={() => this.addSearchProposition()}> {this.props.data.t.read("add_it")}</span>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </React.Fragment>
                                :
                                <div className='font-italic semi-faded mt-1'>
                                    <span>
                                        {this.props.data.t.read("no_results_found")}
                                        {
                                            this.props.hideAddOperation == null || !this.props.hideAddOperation ?
                                                <span className='text-primary' role="button" onClick={() => this.addSearchProposition()}> {this.props.data.t.read("add_it")}</span>
                                                :
                                                null
                                        }
                                    </span>
                                </div>
                        }
                    </div>
                    :
                    null
            }
            <div className="no-wrap text-right form-actions-container">
                {
                    (this.props.hideActionButtons == null || !this.props.hideActionButtons) && !this.state.invalidValue && this.state.wasModified && ((this.state.value + "" !== (this.props.value == null ? "" : this.props.value)) || this.state.startSaving) ?
                        !this.props.locked ?
                            <div className="action-buttons">
                                <button className="btn btn-sm btn-link ml-1 mr-1 " onClick={() => this.save()}>
                                    <FontAwesomeIcon className="text-success" icon={faCheckSquare} />
                                </button>
                                <button className="btn btn-sm btn-link" onClick={() => this.close()}>
                                    <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                </button>
                            </div>
                            :
                            <div className="action-buttons action-buttons-sm">
                                <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                            </div>
                        :
                        null
                }
            </div>
        </div>
    }
}));