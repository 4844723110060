import axios from 'axios';

export const fetch = async (url, method, params, callback, errorCallback = null, isFileUploadForm = false, cancelToken = null) => {
  let headers = {};

  if (params == null) {
    params = {};
  }
  if (window.localStorage.getItem("jwt") != null) {
    headers["common"] = {
      Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
    };
  }

  if (isFileUploadForm) {
    headers['Content-Type'] = 'multipart/form-data';
  }

  try {
    const response = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + url,
      data: params,
      params: (method === "GET" || method === "DELETE") ? params : null,
      headers: headers,
      cancelToken: cancelToken
    });

    if (response.data === "INTERNAL_ERROR") {
      throw new Error(response);
    }

    if (callback) {
      callback(response);
    }
  } catch (e) {
    if (errorCallback) {
      errorCallback(e);
    } else {
      if (e.response === undefined || (e.response.status !== 401 && e.response.status !== 400)) {
        console.error(e);
      }
    }
  }
};