import React, { useState } from 'react';
import { observer, inject } from "mobx-react";
import { useNavigate } from 'react-router-dom';
import logo from './../assets/img/logo-xpens-cropped.svg';
import Translator from '../locales/translator';
import Utils from '../utils/utils';
import { fetch } from '../utils/api-wrapper';

const Signin = inject("data")(observer(({ data }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    

    const navigate = useNavigate();

    const updateLang = () => {
        data.t.switchLang();
        data.t = new Translator();
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("email", email.trim());
        formData.append("password", password);

        fetch("/auth", "POST", formData,
            (response) => {
                window.localStorage.setItem("jwt", response.data.token);
                data.firstName = response.data.first_name;
                data.lastName = response.data.last_name;
                data.username = response.data.username;
                data.unit = response.data.unit;
                data.role = response.data.role;
                data.origin_role = response.data.origin_role;
                data.password_expired = response.data.password_expired;
                data.config = response.data.config;
                data.client_name = response.data.client_name;
                data.id_client = response.data.id_client;
                data.expiration_date = response.data.expiration_date;

                if (data.password_expired) {
                    navigate('/settings');
                } else if (!data.isMobile) {
                    navigate('/dashboard');
                } else if (Utils.userHasPageAccess(data, "expenses")) {
                    navigate('/expenses_accounts');
                } else {
                    data.username = null;
                    setErrorMessage(data.t.read("signin.invalid_credentials_provided"));
                }
            },
            () => {
                setErrorMessage(data.t.read("signin.invalid_credentials_provided"));
            }
        );
    };

    return (
        <div>
            <div
                className="pointer position-absolute"
                style={{ right: 20, top: 20 }}
                onClick={updateLang}
            >
                {
                    data.t.lang === "fr" ?
                        <span><strong>Français</strong> / English</span> :
                        <span>Français / <strong>English</strong></span>
                }
            </div>
            <div className="page-centered">
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="text-center mb-5">
                        <img src={logo} alt="Head Logo" />
                    </div>
                    <div className="alert-section">
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                className="form-control"
                                placeholder="Email"
                                required
                                type="email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                className="form-control"
                                placeholder={data.t.read("password")}
                                required
                                type="password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <button
                                disabled={!emailPattern.test(email)}
                                className="btn btn-block btn-primary"
                            >
                                {data.t.read("signin.signin")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}));

export default Signin;
