import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import {Link} from "react-router-dom";
import Utils from "../../utils/utils";

export default inject("data")(observer(class AdminDashboard extends Component  {
    
    render() {
        return  <div>
            <div className='d-flex'>
                {Utils.userHasRole(this.props.data, ["SuperAdmin"]) &&
                    <div className='mr-2'>
                        <Link to="/admin/clients" className='btn btn-lg btn-primary'>
                            {this.props.data.t.read("clients")}
                        </Link>
                    </div>
                }
                <div>
                    <Link to="/admin/suppliers" className='btn btn-lg btn-primary'>
                        {this.props.data.t.read("suppliers")}
                    </Link>
                </div>
                <div>
                    <Link to="/admin/gl_naturals" className='ml-2 btn btn-lg btn-primary'>
                        {this.props.data.t.read("gl_codes")}                            </Link>
                </div>
                <div>
                    <Link to="/admin/users" className='ml-2 btn btn-lg btn-primary'>
                        {this.props.data.t.read("users")}                            </Link>
                </div>
                <div>
                    <Link to="/admin/offices" className='ml-2 btn btn-lg btn-primary'>
                        {this.props.data.t.read("offices")}                            </Link>
                </div>
            </div>
        </div>
    }
}));